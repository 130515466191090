import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReport } from "../../../services/softSkill.service";
import { useTranslation } from "react-i18next";

const SoftSkillReport = ({ list }) => {
  const [id, setId] = useState(null);
  const [dep, setDep] = useState([]);
  const navigate = useNavigate();
  const [average, setAverage] = useState({ ci: 0, mpi: 0 }); // Initial state for averages
  const { t } = useTranslation();

  useEffect(() => {
    setId(list[0]?.id);
  }, [list]);

  useEffect(() => {
    if (id !== null) {
      getReport(id)
        .then((data) => {
          const totalCi = data.data.reduce(
            (sum, department) => sum + department.averageCi,
            0
          );
          const totalMpi = data.data.reduce(
            (sum, department) => sum + department.averageMpi,
            0
          );
          const numDepartments = data.data.length;

          const averageCi = numDepartments > 0 ? totalCi / numDepartments : 0;
          const averageMpi = numDepartments > 0 ? totalMpi / numDepartments : 0;

          setAverage({ ci: averageCi, mpi: averageMpi });

          const transformedData = data.data.map((department) => {
            console.log(department);
            return {
              depName: department.departmentName || "Unknown",
              depID: department.departmentId,
              scores: [
                {
                  enName: "MPI score",
                  mnName: "MPI оноо",
                  score: department.averageMpi,
                },
                {
                  enName: "CI score",
                  mnName: "CI оноо",
                  score: department.averageCi,
                },
                {
                  enName: "Deductive Reasoning",
                  mnName: "Ерөнхийлөн дүгнэх",
                  score: department.averageDeductiveReasoning,
                },
                {
                  enName: "Inductive Reasoning",
                  mnName: "Нарийвчлан дүгнэх",
                  score: department.averageInductiveReasoning,
                },
                {
                  enName: "Quantitative Reasoning",
                  mnName: "Тоон сэтгэлгээ",
                  score: department.averageQuantitativeReasoning,
                },
                {
                  enName: "Attention",
                  mnName: "Анхаарал",
                  score: department.averageAttention,
                },
                {
                  enName: "Working Memory",
                  mnName: "Ажлын санах ой",
                  score: department.averageWorkingMemory,
                },
                {
                  enName: "Processing Speed",
                  mnName: "Боловсруулах хурд",
                  score: department.averageProcessingSpeed,
                },
                {
                  enName: "Pattern Recognition",
                  mnName: "Хэв таних",
                  score: department.averagePatternRecognition,
                },
                {
                  enName: "Critical Thinking",
                  mnName: "Нухацтай сэтгэлгээ",
                  score: department.averageCriticalThinking,
                },
                {
                  enName: "Decision Making",
                  mnName: "Шийдвэр гаргах чадвар",
                  score: department.averageDecisionMaking,
                },
                {
                  enName: "Planning",
                  mnName: "Төлөвлөх чадвар",
                  score: department.averagePlanning,
                },
                {
                  enName: "Learning Rate",
                  mnName: "Суралцах хурд",
                  score: department.averageLearningRate,
                },
                {
                  enName: "Curiosity",
                  mnName: "Сониуч зан",
                  score: department.averageCuriosity,
                },
                {
                  enName: "Risk Taking",
                  mnName: "Эрсдэл хүлээх чадвар",
                  score: department.averageRiskTaking,
                },
                {
                  enName: "Adaptability",
                  mnName: "Дасан зохицох чадвар",
                  score: department.averageAdaptability,
                },
                {
                  enName: "Consistence",
                  mnName: "Тууштай байдал",
                  score: department.averageConsistence,
                },
                {
                  enName: "Goal Setting",
                  mnName: "Зорилго төлөвлөлт",
                  score: department.averageGoalSetting,
                },
                {
                  enName: "Cooperative",
                  mnName: "Багаар ажиллах чадвар",
                  score: department.averageCooperative,
                },
                {
                  enName: "Trusting",
                  mnName: "Бусдад итгэх хандлага",
                  score: department.averageTrusting,
                },
                {
                  enName: "Assertiveness",
                  mnName: "Өөртөө итгэлтэй, шийдэмгий байдал",
                  score: department.averageAssertiveness,
                },
              ],
            };
          });

          setDep(transformedData);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const headers = [
    t("Softskill.Report.MPI_score"),
    t("Softskill.Report.CI_score"),
    t("Softskill.Report.general_evaluation"),
    t("Softskill.Report.detailed_evaluation"),
    t("Softskill.Report.numerical_thinking"),
    t("Softskill.Report.attention"),
    t("Softskill.Report.working_memory"),
    t("Softskill.Report.processing_speed"),
    t("Softskill.Report.pattern_recognition"),
    t("Softskill.Report.critical_thinking"),
    t("Softskill.Report.decision_making"),
    t("Softskill.Report.planning"),
    t("Softskill.Report.learning_speed"),
    t("Softskill.Report.curiosity"),
    t("Softskill.Report.risk_taking"),
    t("Softskill.Report.adaptability"),
    t("Softskill.Report.perseverance"),
    t("Softskill.Report.goal_setting"),
    t("Softskill.Report.teamwork"),
    t("Softskill.Report.trust_in_others"),
    t("Softskill.Report.self_confidence_decisiveness"),
  ];

  if (dep.length === 0) {
    return (
      <div className="flex items-center justify-between">
        <p className="text-xl font-semibold text-[#1E293B]">
          {t("Softskill.noInfo")}
        </p>
        {list.length > 0 && (
          <select
            onChange={(e) => {
              setId(e.target.value);
            }}
            className="text-sm rounded-lg text-[#1E293B] ps-3 pe-8 py-2"
          >
            {list.map((items, index) => (
              <option key={index} value={items.id}>
                {items.name}
              </option>
            ))}
          </select>
        )}
      </div>
    );
  }
  return (
    <div className="pt-11">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-10">
          <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3 bg-[#fff]">
            <div>
              <p className="text-sm text-[#666874] mb-2">
                {t("Softskill.Report.avgMpi")}
              </p>
              <p className="text-[24px] font-semibold text-[#324D72]">
                {average.mpi.toFixed(0) || "0"}
              </p>
            </div>
          </div>
          <div className="w-[160px] h-[74px] rounded-xl shadow-xl flex items-center px-4 py-3 bg-[#fff]">
            <div>
              <p className="text-sm text-[#666874] mb-2">
                {t("Softskill.Report.avgCi")}
              </p>
              <p className="text-[24px] font-semibold text-[#324D72]">
                {average.ci.toFixed(0) || "0"}
              </p>
            </div>
          </div>
        </div>

        <select
          onChange={(e) => {
            setId(e.target.value);
          }}
          className="text-sm rounded-lg text-[#1E293B] ps-3 pe-8 py-2"
        >
          {list.map((items, index) => (
            <option key={index} value={items.id}>
              {items.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-8">
        <p className="text-[#1E293B] font-semibold">
          {t("Softskill.Report.title")}
        </p>
        <p className="text-sm text-[#777985]">{t("Softskill.Report.desc")}</p>
        <div className="overflow-x-auto my-10">
          <table className="min-w-full bg-white rounded-xl">
            <thead>
              <tr>
                <th className="px-3 py-1 text-left text-sm font-semibold text-[#1E293B] uppercase tracking-wider"></th>
                {headers.map((header, index) => (
                  <th
                    key={index}
                    className={`px-3 py-1 text-xs font-semibold text-[#1E293B] uppercase tracking-wider bg-none text-center`}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="pb-8">
              {dep.map((items, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    onClick={() => {
                      navigate(`report/${id}/${items.depID}`);
                    }}
                    className="px-6 py-4 whitespace-nowrap hover:bg-[#E9EDF5] text-sm font-medium text-gray-900"
                  >
                    {items.depName}
                  </td>
                  {items.scores?.map((cell, cellIndex) => (
                    <td key={cellIndex} className={`px-1 whitespace-nowrap`}>
                      <div className="py-3">
                        <p className="text-center text-sm text-[#324D72] font-bold">
                          {cell.score?.toFixed(0)}
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillReport;
