import React, { useState } from "react";
import MainTable from "../../../components/table/MainTable";
import { IoMdClose } from "react-icons/io";
import Loading from "../../../components/uncommon/Loading";
import { useTranslation } from "react-i18next";

const MeetingTable = ({ data }) => {
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };
  const { t, i18n } = useTranslation();

  const [modalData, setModalData] = useState(null);

  if (data === null) {
    return <Loading />;
  }

  return (
    <div className="relative">
      {" "}
      <MainTable
        columnDefs={[
          {
            headerName: t("Meetings.report.fullname"),
            field: "name",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/house.png" },
          },
          {
            headerName: t("Meetings.report.date"),
            field: "date",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/calendar.png" },
          },
          {
            headerName: t("Meetings.report.time"),
            field: "time",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/time.svg" },
          },
          {
            headerName: t("Meetings.report.infor"),
            field: "allAnket",
            sortable: true,
            flex: 2,
            headerComponent: (hprops) => {
              return (
                <div className="flex justify-center items-center text-[#1E293B]">
                  {hprops.menuIcon != null ? (
                    <img
                      src={`${hprops.menuIcon}`}
                      className="mr-1.5"
                      width={20}
                      height={20}
                    />
                  ) : null}
                  {hprops.displayName}
                </div>
              );
            },
            cellRenderer: (params) => {
              return (
                <div className="flex items-center justify-center">
                  <button
                    onClick={() => {
                      console.log(params.data);
                      setModalData(params.data);
                    }}
                    className="text-[#324D72] p-1 rounded-md border border-[#324D72] text-sm hover:opacity-50"
                  >
                    {t("Meetings.report.seeInfo")}
                  </button>
                </div>
              );
            },
            headerComponentParams: { menuIcon: "/icons/doc.svg" },
          },
        ]}
        rowData={data.talents
          .slice()
          .reverse()
          .map((elm) => {
            return {
              name: `${elm.lastName}, ${elm.firstName}`,
              lastName: elm.lastName,
              firstName: elm.firstName,
              date: elm.date,
              time: elm.startTime,
              endtime: elm.endTime,
              age: elm.age,
              status: elm.currentStatus,
              phone: elm.phone,
              address: elm.address,
              is_fulltime: elm.workTime,
              email: elm.email,
            };
          })}
      />
      {modalData && (
        <div className="w-full h-full fixed left-0 top-0 bg-[#000] bg-opacity-40 flex items-center justify-center z-50">
          <div className="max-w-[500px] p-3 bg-[#fff] rounded-xl">
            <div className="flex items-center justify-end mb-6">
              <button
                onClick={() => setModalData(null)}
                className="p-1 border-2 border-[#CECFD3] rounded-md"
              >
                <IoMdClose className="text-[#707070]" />
              </button>
            </div>
            <div className="flex items-center justify-between gap-4 mb-8">
              <p className="text-[#1A1A1A] ">
                {i18n.language === "en"
                  ? `${t("Meetings.report.schedule")} ${formatDate(
                      modalData.date
                    )}`
                  : `${formatDate(modalData.date)} ${t(
                      "Meetings.report.schedule"
                    )}`}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.firstname")}
              </p>
              <p className="text-[#1E293B] text-[12px]">
                {modalData.firstName}
              </p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.lastname")}
              </p>
              <p className="text-[#1E293B] text-[12px]">{modalData.lastName}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.age")}
              </p>
              <p className="text-[#1E293B] text-[12px]">{modalData.age}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.status")}
              </p>
              <p className="text-[#1E293B] text-[12px]">
                {modalData.currentStatus}
              </p>
            </div>

            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.phone")}
              </p>
              <p className="text-[#1E293B] text-[12px]">{modalData.phone}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.mail")}
              </p>
              <p className="text-[#1E293B] text-[12px]">{modalData.email}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px]">
                {t("Meetings.report.address")}
              </p>
              <p className="text-[#1E293B] text-[12px]">{modalData.address}</p>
            </div>
            <div className="flex items-center justify-between gap-4 mb-3">
              <p className="text-[#1E293B] text-[12px] w-[128px]">
                {t("Meetings.report.fulltime")}
              </p>
              <p className="text-[#1E293B] text-[12px]">
                {modalData.workTime === "fulltime"
                  ? t("Meetings.report.yes")
                  : modalData.workTime === "parttime"
                  ? t("Meetings.report.no")
                  : ""}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeetingTable;
