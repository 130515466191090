import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { getList } from "../../services/meeting.service";
import Loading from "../../components/uncommon/Loading";
import { FaRegCopy } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Meetings = () => {
  const navigate = useNavigate();
  const [list, setList] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    getList()
      .then((data) => setList(data.data))
      .catch((err) => console.log(err));
  }, []);

  const copyUrl = (event, url) => {
    event.stopPropagation();
    const fullUrl = `https://calendar.oneplace.hr/${url}`;
    navigator.clipboard
      .writeText(fullUrl)
      .then(() => {
        toast.success(t("Meetings.copied"));
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
      });
  };

  if (list === null) {
    return <Loading />;
  }

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="w-full p-6 bg-[#fff] rounded-xl">
        <div className="w-full flex items-center justify-between mb-8">
          <p className="text-[24px] text-[#222] ">{t("Meetings.title")}</p>
          <button
            onClick={() => navigate("create")}
            className="ps-6 pe-4 py-3 bg-[#324d72] text-sm text-[#fff] flex items-center gap-3 rounded-xl"
          >
            {t("Meetings.createBtn")}
            <FaPlus />
          </button>
        </div>
        {list.length === 0 ? (
          <div className="my-40 w-full flex items-center justify-center">
            <div>
              <div className="flex justify-center">
                <img src="/onboard/empty.svg" alt="empty" />
              </div>
              <p className="text-center">{t("Meetings.noMeeting")}</p>
            </div>
          </div>
        ) : (
          <div className="h-[68vh] overflow-x-scroll flex flex-wrap gap-6 ">
            {list
              .slice()
              .reverse()
              .map((item, index) => {
                const formattedDate = new Date(
                  item.createdDate
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                });

                return (
                  <div
                    onClick={() => {
                      navigate(`${item.id}`);
                    }}
                    key={index}
                    className="p-3 border border-[#E5E6E8] rounded-xl flex flex-col justify-between w-[275px] h-[75px] bg-[#fff] cursor-pointer"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-[#1E293B] text-sm">{item.name}</p>
                        <p className="text-[#575763] text-xs flex items-center">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M7.5 0.75H4.5V1.75H7.5V0.75ZM5.5 7.25H6.5V4.25H5.5V7.25ZM9.515 3.945L10.225 3.235C10.01 2.98 9.775 2.74 9.52 2.53L8.81 3.24C8.035 2.62 7.06 2.25 6 2.25C3.515 2.25 1.5 4.265 1.5 6.75C1.5 9.235 3.51 11.25 6 11.25C8.49 11.25 10.5 9.235 10.5 6.75C10.5 5.69 10.13 4.715 9.515 3.945ZM6 10.25C4.065 10.25 2.5 8.685 2.5 6.75C2.5 4.815 4.065 3.25 6 3.25C7.935 3.25 9.5 4.815 9.5 6.75C9.5 8.685 7.935 10.25 6 10.25Z"
                                fill="#575763"
                              />
                            </svg>
                          </span>{" "}
                          {item.duration} {t("Meetings.min")}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button onClick={(event) => copyUrl(event, item.url)}>
                          <FaRegCopy />
                        </button>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      <p className="text-[#575763] text-xs">
                        {t("Meetings.created")}: <span>{formattedDate}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Meetings;
