import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getOnboardList, getOnboardEmp } from "../../services/onboard.service";
import Loading from "../../components/uncommon/Loading";
import OnBoardingList from "./onBoarding/OnBoardingList";
import OnBoardingEmp from "./onBoarding/OnBoardingEmp";

const OnBoarding = () => {
  const [list, setList] = useState(null);
  const [emp, setEmp] = useState(null);

  const navigate = useNavigate();
  const {t} = useTranslation()
  const [table, setTable] = useState("list");

  useEffect(() => {
    getOnboardList()
      .then((data) => {
        setList(data.data);
      })
      .catch((err) => console.log(err));
    getOnboardEmp()
      .then((data) => {
        setEmp(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (list === null && emp === null) {
    return <Loading />;
  }

  const handleTableChange = (table) => {
    setTable(table);
    navigate(`/company/onboarding/${table}`);
  };
  const renderActiveTable = () => {
    switch (table) {
      case "list":
        return <OnBoardingList list={list} />;
      case "employee":
        return <OnBoardingEmp emp={emp} />;
      default:
        return null;
    }
  };

  return (
    <div className="pt-11 w-10/12 m-auto">
      <div className="flex items-center justify-between">
        <p className="text-[24px] text-[#222222]">{t("onboard.title")}</p>
        <button
          onClick={() => {
            navigate("create");
          }}
          className="px-6 py-2 rounded-lg bg-[#324D72] text-[#fff] flex items-center gap-2 font-semibold text-xs"
        >
          {t("onboard.createBtn")} <FaPlus />
        </button>
      </div>
      <div className="mb-4 mt-6 flex items-center gap-4">
        <button
          onClick={() => {
            handleTableChange("list");
          }}
          className={`pb-2 w-[100px] ${
            table === "list"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("onboard.page")}
        </button>

        <button
          onClick={() => {
            handleTableChange("employee");
          }}
          className={`pb-2 w-[100px] ${
            table === "employee"
              ? "text-[#FD6B3A] border-b-2 border-[#FD6B3A]"
              : "text-[#4B4B53]"
          }`}
        >
          {t("onboard.page2")}
        </button>
      </div>
      <div>{renderActiveTable()}</div>
    </div>
  );
};

export default OnBoarding;
