import React, { useRef, useState } from "react";
import { LuClock9 } from "react-icons/lu";
import { createMeeting } from "../../../services/meeting.service";
import { useNavigate } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa6";
import { MdOutlineInsertLink } from "react-icons/md";
import { QRCodeCanvas } from "qrcode.react";
import { useTranslation } from "react-i18next";

const CreateMeeting = () => {
  const [time, setTime] = useState("30");
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [popUp, setPopUp] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const qrCanvasRef = useRef(null);
  const [url, setUrl] = useState("");
  const { t } = useTranslation();

  const [schedule, setSchedule] = useState([
    { day: t("Meetings.create.1"), enabled: true, time: "09:00", endtime: "" },
    { day: t("Meetings.create.2"), enabled: true, time: "09:00", endtime: "" },
    { day: t("Meetings.create.3"), enabled: true, time: "09:00", endtime: "" },
    { day: t("Meetings.create.4"), enabled: true, time: "09:00", endtime: "" },
    { day: t("Meetings.create.5"), enabled: true, time: "09:00", endtime: "" },
  ]);

  const toggleDay = (index) => {
    setSchedule((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, enabled: !item.enabled } : item
      )
    );
  };

  const changeTime = (index, newTime, type) => {
    if (type === "start") {
      setSchedule((prev) =>
        prev.map((item, i) => (i === index ? { ...item, time: newTime } : item))
      );
    } else {
      setSchedule((prev) =>
        prev.map((item, i) =>
          i === index ? { ...item, endtime: newTime } : item
        )
      );
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`https://calendar.oneplace.hr/${url}`)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const downloadQRCode = () => {
    const canvas = qrCanvasRef.current;
    if (canvas) {
      const imageUrl = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "QRCode.png";
      link.click();
    } else {
      console.error("QR code canvas not found.");
    }
  };

  const isFormValid = () => {
    const hasEnabledSchedules = schedule.some((item) => item.enabled);
    const allEnabledHaveEndTime = schedule
      .filter((item) => item.enabled)
      .every((item) => item.endtime.trim() !== "");
    const isTitleValid = title.trim() !== "";
    const isLocationValid = location.trim() !== "";

    return (
      hasEnabledSchedules &&
      allEnabledHaveEndTime &&
      isTitleValid &&
      isLocationValid
    );
  };

  const submit = async () => {
    setLoading(true);
    const dayMapping = {
      даваа: 1,
      мягмар: 2,
      лхагва: 3,
      пүрэв: 4,
      баасан: 5,
      бямба: 6,
      ням: 7,
    };

    const schedules = schedule
      .filter((entry) => entry.enabled)
      .map((entry) => {
        const dayKey = entry.day?.toLowerCase().trim();
        const dayOfWeek = dayMapping[dayKey];

        return {
          dayOfWeek,
          startTime: entry.time,
          endTime: entry.endtime,
          isActive: entry.enabled,
        };
      })
      .filter((entry) => entry.dayOfWeek !== undefined);

    const submitData = {
      name: title,
      userId: 1,
      companyId: 101,
      duration: time,
      address: location,
      schedules: schedules,
    };

    createMeeting(submitData)
      .then((data) => {
        setPopUp(true);
        setLoading(false);
        setUrl(data.data.url);
      })
      .catch((err) => {
        console.error("Error creating meeting:", err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="w-10/12 m-auto pt-11 relative">
      <div>
        <p className="text-[#222] text-[24px] mb-10">
          {t("Meetings.create.title")}
        </p>
        <div className="w-full flex items-start justify-between gap-10">
          <div className="w-[30%]">
            <p className="text-[#1E293B] mb-8">{t("Meetings.create.desc")}</p>
            <div>
              <div className="mb-6">
                <p className="text-[#111827] text-sm mb-2">
                  {t("Meetings.create.name")}{" "}
                  <span className="text-[#FF5F6E]">*</span>
                </p>
                <input
                  className="w-full rounded-xl text-sm p-3 border border-[#575763]"
                  placeholder={t("Meetings.create.placeholder")}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  value={title}
                  type="text"
                />
              </div>

              <div className="mb-6">
                <p className="text-[#111827] text-sm mb-2">
                  {t("Meetings.create.duration")}{" "}
                  <span className="text-[#FF5F6E]">*</span>
                </p>
                <div className="relative w-full">
                  <select
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                    value={time}
                    className="w-full rounded-xl text-sm p-3 border border-[#575763] appearance-none ps-8"
                  >
                    <option value="10">10 {t("Meetings.create.min")}</option>
                    <option value="20">20 {t("Meetings.create.min")}</option>
                    <option value="30">30 {t("Meetings.create.min")}</option>
                    <option value="40">40 {t("Meetings.create.min")}</option>
                    <option value="50">50 {t("Meetings.create.min")}</option>
                    <option value="60">1 {t("Meetings.create.hour")}</option>
                    <option value="70">
                      1 {t("Meetings.create.hour")} 10{" "}
                      {t("Meetings.create.min")}
                    </option>
                    <option value="80">
                      1 {t("Meetings.create.hour")} 20{" "}
                      {t("Meetings.create.min")}
                    </option>
                    <option value="90">
                      1 {t("Meetings.create.hour")} 30{" "}
                      {t("Meetings.create.min")}
                    </option>
                    <option value="100">
                      1 {t("Meetings.create.hour")} 40{" "}
                      {t("Meetings.create.min")}
                    </option>
                    <option value="110">
                      1 {t("Meetings.create.hour")} 50{" "}
                      {t("Meetings.create.min")}
                    </option>
                    <option value="120">2 {t("Meetings.create.hour")}</option>
                  </select>
                  <LuClock9 className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-[#6B7280] pointer-events-none" />
                </div>
              </div>
              <div className="mb-6">
                <p className="text-[#111827] text-sm mb-2">
                  {t("Meetings.create.location")}{" "}
                  <span className="text-[#FF5F6E]">*</span>
                </p>
                <textarea
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  value={location}
                  className="w-full h-[64px] rounded-xl text-sm p-3 border border-[#575763]"
                  placeholder={t("Meetings.create.placeholder2")}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="w-[70%]">
            <p className="text-[#1E293B] mb-8">
              {t("Meetings.create.meetingDay")}
            </p>
            <div>
              {schedule.map((item, index) => (
                <div
                  key={item.day}
                  className={`flex items-center justify-between pb-5  ${
                    index === 0 ? "" : "border-t border-[#D1D5DB] pt-4"
                  }`}
                >
                  <div className="flex items-center gap-2">
                    <label className="relative flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only"
                        checked={item.enabled}
                        onChange={() => toggleDay(index)}
                      />
                      <div
                        className={`w-[44px] h-[24px] rounded-2xl transition-colors ${
                          item.enabled ? "bg-[#2C3E5E]" : "bg-gray-300"
                        }`}
                      ></div>
                      <div
                        className={`absolute left-0.5 top-0.5 w-[20px] h-[20px] bg-white rounded-2xl shadow-md transition-transform ${
                          item.enabled ? "translate-x-5" : "translate-x-0"
                        }`}
                      ></div>
                    </label>
                    <p className="text-[#111827] text-sm ms-3">{item.day}</p>
                    {!item.enabled && (
                      <p className="text-[#4B5563] text-sm ">
                        {t("Meetings.create.notMeeting")}
                      </p>
                    )}
                  </div>
                  {item.enabled && (
                    <div className="flex items-center gap-3">
                      <input
                        type="time"
                        value={item.time}
                        disabled={!item.enabled}
                        onChange={(e) =>
                          changeTime(index, e.target.value, "start")
                        }
                        className={`border border-[#D1D5DB] rounded-md px-1 py-0.5 text-sm ${
                          item.enabled
                            ? "bg-white"
                            : "bg-gray-200 cursor-not-allowed"
                        }`}
                      />
                      <p className="text-[#4B5563] text-sm">to</p>
                      <input
                        type="time"
                        value={item.endtime}
                        disabled={!item.enabled}
                        onChange={(e) =>
                          changeTime(index, e.target.value, "end")
                        }
                        className={`border border-[#D1D5DB] rounded-md px-1 py-0.5 text-sm ${
                          item.enabled
                            ? "bg-white"
                            : "bg-gray-200 cursor-not-allowed"
                        }`}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-end">
          <button
            disabled={!isFormValid() || loading}
            onClick={submit}
            className={`px-6 py-2 text-[#FFF] rounded-md flex items-center justify-center ${
              isFormValid() && !loading
                ? "bg-[#324d72]"
                : "bg-[#CECFD3] cursor-default"
            }`}
          >
            {loading ? (
              <div className="w-5 h-5 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
            ) : (
              t("Meetings.create.create")
            )}
          </button>
        </div>
      </div>
      {popUp && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-[#fff]  rounded-xl  pb-6 pt-4 relative px-6">
            <p className="text-[#1A1A1A] text-lg  font-semibold mb-4">
              {t("Meetings.create.created")}
            </p>
            <div className="flex items-center gap-4">
              <p className="text-[#1A1A1A]">{t("Meetings.create.link")}</p>
              <div className="flex items-cetner gap-8">
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() =>
                    window.open(`https://calendar.oneplace.hr/${url}`, "_blank")
                  }
                >
                  <MdOutlineInsertLink />
                  <p className="text-[#222] text-sm">
                    {`https://calendar.oneplace.hr/${
                      url.length > 30 ? url.substring(0, 10) + "..." : url
                    }`}
                  </p>
                </div>

                <button
                  onClick={handleCopy}
                  className="text-[#222] flex items-center gap-2"
                >
                  <FaRegCopy />
                  {isCopied && (
                    <span className="text-green-500 text-xs">
                      {t("Meetings.create.copied")}
                    </span>
                  )}
                </button>
              </div>
            </div>
            {url !== "" && (
              <div className="flex items-center justify-center my-5">
                <QRCodeCanvas
                  ref={qrCanvasRef}
                  value={`https://calendar.oneplace.hr/${url}`}
                  size={200}
                />
              </div>
            )}

            <div className="flex items-center justify-end gap-4">
              <button
                onClick={downloadQRCode}
                className="px-3 py-2 bg-[#324d72] text-[#fff] rounded-lg text-sm"
              >
                {t("Meetings.create.qrcode")}
              </button>
              <button
                onClick={() => {
                  navigate("/company/meetings");
                }}
                className="bg-[#324d72] text-[#fff] text-sm px-3 py-2 rounded-lg"
              >
                {t("Meetings.create.unders")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateMeeting;
