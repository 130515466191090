import { Box, CircularProgress, Drawer, Modal, TextField } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SiMicrosoftexcel } from "react-icons/si";
import ReactQuill from "react-quill";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MainButton from "../../components/buttons/MainButton";
import {
  assessmentById,
  assessmentPlayers,
  changeTime,
  createAssessmentEmail,
  createAssessmentPlayers,
  downloadAssessmentExcel,
  findAssessmentEmail,
  getAssessmentLink,
} from "../../services/assessment.service";
import { deletePlayerApi } from "../../services/assessmentPlayer.service";
import AssessmentTable from "./AssessmentsTable";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  [{ header: 1 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["clean"], // remove formatting button
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "white",
  // boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const linkstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  border: "white",
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const emailStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 2 / 3,
  bgcolor: "background.paper",
  border: "white",
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const Assessment = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [players, setPlayers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [ass, setAss] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [showLink, setShowLink] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [emailText, setEmailText] = useState("");
  const [inviteLink, setInviteLink] = useState(null);
  const { t, i18n } = useTranslation();
  const [copyMessage, setCopyMessage] = useState("");

  useEffect(() => {
    getAssessment();
  }, []);

  useEffect(() => {
    getAssessmentLink(id)
      .then((data) => setInviteLink(data.data))
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    const currentTime = moment().format("YYYY-MM-DDTHH:mm");
    setStart(currentTime);
  }, []);

  const deletePlayer = (assess) => {
    deletePlayerApi({
      assessmentId: assess.assessmentId,
      assPlayerId: assess.id,
    }).then((res) => {
      const { data } = res;
      setPlayers(data);
      toast.success(t("ResponseMsg.sucessfullySend"));
    });
  };

  const getAssessment = async () => {
    setShowModal(true);
    await assessmentById(id)
      .then((val) => {
        const { data } = val;
        setPlayers(
          data?.assessmentplayerIds.filter((v) => v.isActive === true)
        );
        setAss(data);
        if (data.startTime !== null && data.endTime) {
          setStart(moment(data.startTime).format("YYYY-MM-DDThh:mm"));
          setEnd(moment(data.endTime).format("YYYY-MM-DDThh:mm"));
        }
      })
      .catch((err) => {
        toast.error("Wrong assessemnt");
      });
    setShowModal(false);
  };

  const addNewPlayer = async () => {
    if (email == null || email === "") {
      toast.error(t("toast.fillAllTheField"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      setShowModal(true);
      createAssessmentPlayers({
        name: name,
        email,
        lastName,
        assessmentId: id,
        // language: i18n.language === "rn" ? 1 : 0,
      })
        .then((res) => {
          assessmentPlayers(id).then((res2) => {
            const { data } = res2;
            setPlayers(data);
          });
          // setShowModal(false);
        })
        .catch((err) => {
          // setShowModal(false);
          toast.error(t("toast.emailIsWrong"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      setName("");
      setEmail("");
      setLastName("");
      toast.success(t("ResponseMsg.sucessfullySend"));
      setShowModal(false);
    }
  };

  const addTime = () => {
    if (start === "") {
      toast.error(t("toast.writeCorrectStartDate"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    if (end === "") {
      toast.error(t("toast.writeCorrectEndDate"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (moment(start).isSameOrAfter(moment(end))) {
      toast.error(t("toast.writeCorrectEndAndStartDate"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    changeTime({ start: start, end: end, assessmentId: id })
      .then((res) => {
        toast.success(t("toast.SuccessfullySaved"));
      })
      .catch((e) => {
        toast.error(t("toast.Error"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const copyLink = () => {
    if (inviteLink) {
      navigator.clipboard
        .writeText(inviteLink)
        .then(function () {
          setCopyMessage(t("toast.LinkCopied"));
          setTimeout(() => {
            setCopyMessage("");
          }, 2000);
        })
        .catch(function (err) {
          console.error(err);
          setCopyMessage(t("toast.copyFailed"));
        });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex  justify-center content-center	">
            <div className="">
              <CircularProgress className="ml-11" />
              <p className="text-center text-lg">{t("GameFlow.loading")} ...</p>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={showLink}
        onClose={() => setShowLink(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={linkstyle}>
          <div>
            <p className="font-bold">{t("Assessment.writeName")}</p>
            <input
              type="text"
              value={name}
              placeholder={t("Form.name")}
              className="w-full border border-gray-300 text-gray-900 font-bold rounded-lg focus:outline-none focus:ring-0 focus:border-main-blue mt-[10px] mb-[15px]"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <p className="font-bold">{t("Assessment.sendInvitationByEmail")}</p>
            <input
              value={email}
              type="text"
              placeholder={t("Assessment.writeEmail")}
              className="w-full border border-gray-300 text-gray-900 font-bold rounded-lg focus:outline-none focus:ring-0 focus:border-main-blue mt-[10px] mb-[15px]"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="flex justify-end ">
              <div className="w-[110px]">
                <MainButton
                  onClick={addNewPlayer}
                  label={t("Assessment.sent")}
                  color={"bg-main-blue"}
                  labelColor={"text-white"}
                />
              </div>
            </div>
            <hr className="mt-7" />
            <p className="mt-[20px]">{t("Assessment.shareAssessment")}</p>
            <div className="flex  py-2  mt-[10px] mb-[15px] ">
              <input
                disabled
                value={inviteLink}
                type="text"
                className="w-full border border-gray-300 text-gray-900 font-bold rounded-lg focus:outline-none focus:ring-0 focus:border-main-blue mr-[15px]"
              />
              <div className="relative">
                {copyMessage && (
                  <div className="bg-green-200 w-full text-xs text-green-800 p-2 rounded mb-2 absolute top-[-40px] left-1/2 transform -translate-x-1/2">
                    {copyMessage}
                  </div>
                )}
                <div className="w-[150px]">
                  <MainButton
                    label={"Copy link"}
                    color={"bg-main-blue"}
                    labelColor={"text-white"}
                    onClick={copyLink}
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex  justify-center content-center	">
            <div className="">
              <CircularProgress className="ml-11" />
              <p className="text-center text-lg">{t("GameFlow.loading")} ...</p>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={showEmail}
        onClose={() => setShowEmail(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="xl"
      >
        <Box sx={emailStyle}>
          <div>
            <p className="mt-2 mb-2 font-bold text-center text-[18px]">
              {t("Assessment.pleaseChangeEmail")}
            </p>
            <ReactQuill
              theme="snow"
              modules={{ toolbar: toolbarOptions }}
              value={emailText}
              onChange={(e) => {
                console.log(e);
                setEmailText(e);
              }}
            />
          </div>
          <div className="mt-2">
            <MainButton
              label={t("Assessment.save")}
              color={"bg-main-blue"}
              labelColor={"text-white"}
              onClick={() => {
                createAssessmentEmail({
                  assessmentId: id,
                  email: emailText,
                }).then((res) => {
                  toast.success(t("ResponseMsg.successfullyStored"));
                  setShowEmail(false);
                });
              }}
            />
          </div>
        </Box>
      </Modal>
      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
      >
        {/* {list(anchor)} */}
        <div className="flex flex-col h-full justify-center items-center">
          <div className="mt-11 mr-4 ">
            <div className="pl-4 col-span-2 	">
              <div className="mb-4 text-[18px]">
                <p>{t("Assessment.selectGameDate")}</p>
              </div>
              <div className="flex">
                <div>
                  <TextField
                    id="datetime-local"
                    label={`${t("Assessment.startDate")}`}
                    type="datetime-local"
                    value={start}
                    className={""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(val) => {
                      setStart(val.target.value);
                    }}
                  />
                </div>
                <div className="ml-4 ">
                  <TextField
                    id="datetime-local"
                    label={`${t("Assessment.endDate")}`}
                    type="datetime-local"
                    value={end}
                    className={""}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        paddingRight: 10,
                      },
                    }}
                    onChange={(val) => {
                      console.log(val.target.value);
                      setEnd(val.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <button
                  className={`h-full w-full rounded-xl text-white py-3 text-lg ${
                    end !== "" && start !== "" ? "bg-[#324d72]" : "bg-[#ddd]"
                  }`}
                  onClick={() => {
                    addTime();
                  }}
                >
                  {t("Assessment.save")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <div className="w-full">
        <ToastContainer />
        <div>
          <div className="flex">
            <p className="pr-1">
              <Link className="underline" to="/">
                {t("Home.jobs")}
              </Link>
              {" >"}
            </p>{" "}
            <p>{ass && ass.name ? ass.name : "Оролцогчид урих"}</p>
          </div>
          <div className="flex">
            <div className="flex block rounded-lg p-[20px] shadow-lg items-center ">
              <div className=" w-[60px] h-[60px] mr-4">
                <img src="/icons/anketNumber.png" alt="" />
              </div>
              <div>
                <p className="text-[42px] text-main-blue">
                  {players?.length || 0}
                </p>
                <p>{t("Assessment.totalAnket")}</p>
              </div>
            </div>
            <div className="flex block rounded-lg p-[20px] shadow-lg items-center ml-4">
              <div className=" w-[60px] h-[60px] mr-4">
                <img src="/icons/controller.png" alt="" />
              </div>
              <div>
                <p className="text-[42px] text-main-blue">
                  {players?.reduce((prev, val) => {
                    return prev + (val.completeType === "completed" ? 1 : 0);
                  }, 0) || 0}
                </p>
                <p>{t("Assessment.gamePlayedCount")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-nowrap pt-[30px]  justify-between">
          <div>{/* <SearchButton /> */}</div>
          <div className="flex ">
            <div className="pr-2">
              <MainButton
                label={t("Assessment.gameInvitation")}
                color={"bg-main-blue"}
                labelColor={"text-white"}
                onClick={() => {
                  setShowLink(true);
                }}
              />
            </div>
            <div className="pr-2">
              <MainButton
                label={t("Assessment.emailText")}
                color={"bg-main-blue"}
                labelColor={"text-white"}
                onClick={() => {
                  findAssessmentEmail({ assessmentId: id }).then((res) => {
                    setEmailText(res.data.email);
                  });
                  setShowEmail(true);
                }}
              />
            </div>
            <div className="pr-2">
              <MainButton
                label={t("Assessment.deadline")}
                color={"bg-main-blue"}
                labelColor={"text-white"}
                onClick={() => {
                  setShowDrawer(true);
                }}
              />
            </div>
            <div>
              <button
                onClick={() => {
                  downloadAssessmentExcel({ assessmentId: id });
                }}
                className="bg-main-green py-2 px-4 flex items-center gap-4 text-white rounded-xl hover:opacity-60"
              >
                <SiMicrosoftexcel />
                {t("Assessment.downloadExcel")}
              </button>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <AssessmentTable players={players} deletePlayer={deletePlayer} />
        </div>
      </div>
    </div>
  );
};

export default Assessment;
