import { Box, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSend } from "react-icons/ai";
import MainTable from "../../../components/table/MainTable";
import MainButton from "../../../components/buttons/MainButton";
import { ToastContainer, toast } from "react-toastify";
import {
  createBurnoutPlayer,
  exportCreateBurnout,
  getBurnoutCompanyOtherPlayer,
} from "../../../services/burnout.service";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { BsPlus } from "react-icons/bs";
import Loader from "../../../components/uncommon/loader";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoCloudUploadOutline } from "react-icons/io5";

const linkstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  border: "white",
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "25px",
};

const ContractEmployeeTable = () => {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [showLink, setShowLink] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const qrCodeRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [sending, setSending] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // getCompanyContractEmployee().then((data) => {
    //   setEmployees(data.data);
    // });
    getBurnoutCompanyOtherPlayer().then((val) => {
      console.log("val = ", val);
      setEmployees(val.data);
    });
  }, []);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDownloadExcel = async () => {
    try {
      const response = await fetch("/excel/EmployeesExcelFileTemplate.xlsx");
      const blob = await response.blob();

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "EmployeesExcelFileTemplate.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(selectedFile);
      } else {
        setFile(null);
        toast.error("Please select an Excel file (.xlsx or .xls)");
      }
    }
  };

  const downloadQRCode = () => {
    html2canvas(qrCodeRef.current).then((canvas) => {
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "QRCode.png";
      document.body.appendChild(a);
      a.click();
    });
  };

  function isValidEmail(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const addNewPlayer = async () => {
    // Validate email and name
    if (!email || !name) {
      toast.error(t("toast.fillAllTheField"), {});
      return;
    }

    if (!isValidEmail(email)) {
      toast.error(t("toast.fillAllTheField"), {});
      return;
    }

    try {
      // Your API call to createAssessmentPlayers
      // Example: await createAssessmentPlayers({ name, email, lastName, assessmentId: id });
      createBurnoutPlayer({
        name: name,
        email: email,
      }).then((val) => {
        console.log("val=", val);
      });

      // Additional logic after successful API call
      const newEmployee = {
        name: name,
        email: email,
        completeGameType: "invited",
      };

      setName("");
      setEmail("");
      toast.success(t("ResponseMsg.sucessfullySend"));

      setEmployees([newEmployee, ...employees]);
    } catch (err) {
      // Error handling
      toast.error(t("toast.Error"), {});
    }
  };
  return (
    <div className="mt-10">
      <ToastContainer />

      <Modal
        open={showLink}
        onClose={() => setShowLink(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={linkstyle}>
          <div>
            <p className="font-bold">{t("Assessment.writeName")}</p>
            <input
              type="text"
              value={name}
              placeholder={t("Form.name")}
              className="w-full border border-gray-300 text-gray-900 font-bold rounded-lg focus:outline-none focus:ring-0 focus:border-main-blue mt-[10px] mb-[15px]"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <p className="font-bold">{t("Assessment.sendInvitationByEmail")}</p>
            <input
              value={email}
              type="text"
              placeholder={t("Assessment.writeEmail")}
              className="w-full border border-gray-300 text-gray-900 font-bold rounded-lg focus:outline-none focus:ring-0 focus:border-main-blue mt-[10px] mb-[15px]"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className="flex justify-end ">
              <div className="w-[110px]">
                <MainButton
                  onClick={addNewPlayer}
                  label={t("Assessment.sent")}
                  color={"bg-main-blue"}
                  labelColor={"text-white"}
                />
              </div>
            </div>
            {/* <div className="flex justify-center pt-7">
              <div className="flex flex-col items-center">
                <div ref={qrCodeRef}>
                  <QRCode value="CompanyCode" size={128} />
                </div>
                <button onClick={downloadQRCode}>Download QR Code</button>
              </div>
            </div> */}
          </div>
        </Box>
      </Modal>
      <Modal open={open} onClose={handleClose}>
        <div className="absolute top-1/2 left-1/2 w-[650px]  rounded-xl transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 shadow-xl">
          <p className="text-xl text-bold">Excel ашиглан олноор нэмэх.</p>
          <div className="flex justify-between items-center mt-4">
            <p className="text-lg text-[#324d72]">
              Жишээ Excel файл татаж авах
            </p>
            <button
              onClick={handleDownloadExcel}
              className="flex items-center text-white bg-[#55D6A0] py-2 px-3 rounded-lg me-2 gap-2 hover:opacity-80"
            >
              <img src="/icons/excel.png" />
              Татаж авах
            </button>
          </div>
          <p className="text-xl font-bold mt-7">
            Ажилчдын бүртгэлтэй файл сонгоно уу1
          </p>
          <div className="flex justify-between items-center mt-4">
            {file === null ? (
              <p className="p-2 border-black border-2 rounded-lg w-full me-5 italic">
                Excel файл сонгоно уу
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  onChange={handleFileInputChange}
                />
              </p>
            ) : (
              <p className="p-2 border-black border-2 rounded-lg w-full me-5 italic">
                {file.name} {/* shows selected file name */}
              </p>
            )}
            <button className="border-2 p-1 rounded-full border-[#324d72] text-[#324d72] hover:bg-[#324d72]  hover:text-white">
              <label htmlFor="fileInput" className="cursor-pointer">
                <BsPlus className="h-7 w-7" />
              </label>
              <input
                id="fileInput"
                type="file"
                className="hidden"
                accept=".xlsx, .xls"
                onChange={handleFileInputChange}
              />
            </button>
          </div>
          <div className="w-full flex justify-end mt-6">
            <button
              // disabled={sending}
              onClick={async () => {
                if (file === null) {
                  toast.error(t("toast.addExcelFile"));
                  return;
                }
                if (sending === false) {
                  const formData = new FormData();
                  formData.append("file", file);
                  setSending(true);
                  try {
                    await exportCreateBurnout(formData).then((val) => {});
                    // handleClose();
                    setFile(null);
                    toast.success(t("toast.successfulSent"));
                    setSending(false);
                  } catch (error) {
                    toast.error(t("toast.excelFailed"));
                    setSending(false);
                  }
                }
              }}
              className="text-lg px-6 py-2 rounded-lg text-white bg-[#324d72] hover:opacity-50"
            >
              {sending ? (
                <CircularProgress size={25} />
              ) : (
                <div className="flex items-center gap-2">
                  <IoCloudUploadOutline />
                  Оруулах
                </div>
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex justify-end">
        <div className="flex gap-2">
          <button
            className="flex items-center bg-[#324d72] text-white py-2 px-4 gap-2 rounded-lg hover:bg-[#f4f4f4] hover:text-black"
            onClick={() => {
              setShowLink(true);
            }}
          >
            {t("burnout.eachEmployee.sent")}{" "}
            <AiOutlineSend className="text-white" />
          </button>
          {/* <button className="flex items-center bg-[#324d72] text-white py-2 px-4 rounded-lg hover:bg-[#f4f4f4] hover:text-black">
            {t("Assessment.emailText")}
          </button> */}
          <div>
            <button
              className="flex items-center gap-3 bg-main-green rounded-xl py-2 px-5 text-white hover:opacity-60"
              onClick={() => {
                // downloadAssessmentExcel({ assessmentId: id });
                setOpen(true);
              }}
            >
              <SiMicrosoftexcel />
              {t("burnout.eachEmployee.excelBtn")}{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <MainTable
          columnDefs={[
            {
              headerName: t("EmployeeTable.tableSurname"),
              field: `name`,
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
              onCellClicked: (params) => {
                const employee = params.data;
                if (employee) {
                  if (employee.completeGameType === "completed") {
                    navigate(`/company/burnout/report/demo/${employee.id}`, {
                      state: { employeeData: employee },
                    });
                  } else {
                    return "";
                  }
                }
              },
            },
            {
              headerName: t("burnout.eachEmployee.department"),
              field: "departmentName",
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
              onCellClicked: (params) => {
                const employee = params.data;
                if (employee) {
                  if (employee.completeGameType === "completed") {
                    navigate(`/company/burnout/report/demo/${employee.id}`, {
                      state: { employeeData: employee },
                    });
                  } else {
                    return "";
                  }
                }
              },
            },
            {
              headerName: t("burnout.eachEmployee.email"),
              field: "email",
              sortable: true,
              flex: 2,
              cellClass: "cursor-pointer",
              onCellClicked: (params) => {
                const employee = params.data;
                if (employee) {
                  if (employee.completeGameType === "completed") {
                    navigate(`/company/burnout/report/demo/${employee.id}`, {
                      state: { employeeData: employee },
                    });
                  } else {
                    return "";
                  }
                }
              },
            },
            {
              headerName: t("burnout.eachEmployee.status"),
              sortable: true,
              flex: 2,
              field: "completeGameType",
              cellClass: "cursor-pointer",
              onCellClicked: (params) => {
                const employee = params.data;
                if (employee) {
                  if (employee.completeGameType === "completed") {
                    navigate(`/company/burnout/report/demo/${employee.id}`, {
                      state: { employeeData: employee },
                    });
                  } else {
                    return "";
                  }
                }
              },
            },
            {
              headerName: t("burnout.eachEmployee.level"),
              sortable: true,
              flex: 2,
              field: "completeGameType",
              cellClass: "cursor-pointer",
              onCellClicked: (params) => {
                const employee = params.data;
                if (employee) {
                  if (employee.completeGameType === "completed") {
                    navigate(`/company/burnout/report/demo/${employee.id}`, {
                      state: { employeeData: employee },
                    });
                  } else {
                    return "";
                  }
                }
              },
              cellRenderer: (params) => {
                switch (params.data.burnoutLevel) {
                  case null:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/grayDot.png" alt="" />
                        </div>
                        <p>No result</p>
                      </div>
                    );
                  case 1:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/redDot.png" alt="" />
                        </div>
                        <p>1-{t("burnout.generalReport.burnout")}</p>
                      </div>
                    );
                  case 2:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/orangeDot.png" alt="" />
                        </div>
                        <p>2-{t("burnout.generalReport.payAttention")}</p>
                      </div>
                    );
                  case 3:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/purpleDot.png" alt="" />
                        </div>
                        <p>3-{t("burnout.generalReport.overloaded")}</p>
                      </div>
                    );
                  case 4:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/blueDot.png" alt="" />
                        </div>
                        <p>4-{t("burnout.generalReport.decreasedProd")}</p>
                      </div>
                    );
                  case 5:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/greenEllipse.png" alt="" />
                        </div>
                        <p>5-{t("burnout.generalReport.Productive")}</p>
                      </div>
                    );
                  default:
                    return (
                      <div className="flex items-center">
                        <div className="w-2 h-2 flex items-center justify-center mr-1">
                          <img src="/icons/grayDot.png" alt="" />
                        </div>
                        <p>{t("burnout.generalReport.noResponse")}</p>
                      </div>
                    );
                }
              },
            },
          ]}
          rowData={employees}
        />
      </div>
    </div>
  );
};

export default ContractEmployeeTable;
