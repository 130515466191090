import React, { useEffect, useRef, useState } from "react";
import { getPlayer } from "../../../services/assessmentPlayer.service";
import { getPlayerReport } from "../../../services/game.service";
import { useParams } from "react-router-dom";
import Loading from "../../../components/uncommon/Loading";
import { FaUser } from "react-icons/fa6";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const label = [
  "attention",
  "deductiveReasoning",
  "inductiveReasoning",
  "processingSpeed",
  "quantitativeReasoing",
  "workingMemory",
];

const label2 = ["cooperative", "trusting", "assertiveness"];

const label3 = [
  "criticalThinking",
  "decisionMaking",
  "learningRate",
  "patternRecognition",
  "planning",
];

const label4 = ["consistence", "goalSetting"];

const label5 = ["adaptability", "curiosity", "riskTaking"];

const SoftSkillDetail = () => {
  const [data, setData] = useState(null);
  const [report, setReport] = useState(null);
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const reportRef = useRef(null);

  useEffect(() => {
    if (id) {
      getPlayer(id)
        .then((data) => {
          setData(data.data[0]);
          console.log("data", data.data);
        })
        .catch((err) => {
          console.log(err);
        });
      getPlayerReport(id)
        .then((data) => {
          const raw = data.data;

          const agreeable = {
            cooperative: {
              value: raw.agreeable.cooperative,
              rs: "Сотрудничество",
              en: "Cooperative",
              mn: "Багаар ажиллах чадвар",
            },
            trusting: {
              value: raw.agreeable.trusting,
              rs: "Доверие",
              en: "Trust in Others",
              mn: "Бусдад итгэх хандлага",
            },
            assertiveness: {
              value: raw.agreeable.assertiveness,
              rs: "Уверенность",
              en: "Assertiveness",
              mn: "Өөртөө итгэлтэй, шийдэмгий байдал",
            },
            text: raw.agreeable.txt,
          };

          const competencies = {
            criticalThinking: {
              value: raw.competencies.criticalThinking,
              rs: "Критическое мышление",
              en: "Critical Thinking",
              mn: "Нухацтай сэтгэлгээ",
            },
            decisionMaking: {
              value: raw.competencies.decisionMaking,
              rs: "Принятие решений",
              en: "Decision Making",
              mn: "Шийдвэр гаргах чадвар",
            },
            learningRate: {
              value: raw.competencies.learningRate,
              rs: "Скорость обучения",
              en: "Learning Rate",
              mn: "Суралцах хурд",
            },
            patternRecognition: {
              value: raw.competencies.patternRecognition,
              rs: "Распознавание шаблонов",
              en: "Pattern Recognition",
              mn: "Хэв таних",
            },
            planning: {
              value: raw.competencies.planning,
              rs: "Планирование",
              en: "Planning",
              mn: "Төлөвлөх чадвар",
            },
            text: raw.competencies.txt,
          };

          const conscientiousness = {
            consistence: {
              value: raw.conscientiousness.consistence,
              rs: "Последовательность",
              en: "Consistency",
              mn: "Тууштай байдал",
            },
            goalSetting: {
              value: raw.conscientiousness.goalSetting,
              rs: "Постановка целей",
              en: "Goal Setting",
              mn: "Зорилго төлөвлөлт",
            },
            text: raw.conscientiousness.txt,
          };

          const generalIntelligence = {
            attention: {
              value: raw.generalIntelligence.attention,
              rs: "Внимание",
              en: "Attention",
              mn: "Анхаарал",
            },
            deductiveReasoning: {
              value: raw.generalIntelligence.deductiveReasoning,
              rs: "Дедуктивное мышление",
              en: "Deductive Reasoning",
              mn: "Ерөнхийлөн дүгнэх",
            },
            inductiveReasoning: {
              value: raw.generalIntelligence.inductiveReasoning,
              rs: "Индуктивное мышление",
              en: "Inductive Reasoning",
              mn: "Нарийвчлан дүгнэх",
            },
            processingSpeed: {
              value: raw.generalIntelligence.processingSpeed,
              rs: "Скорость обработки",
              en: "Processing Speed",
              mn: "Боловсруулах хурд",
            },
            quantitativeReasoing: {
              value: raw.generalIntelligence.quantitativeReasoing,
              rs: "Количественное мышление",
              en: "Quantitative Reasoning",
              mn: "Тоон сэтгэлгээ",
            },
            workingMemory: {
              value: raw.generalIntelligence.workingMemory,
              rs: "Рабочая память",
              en: "Working Memory",
              mn: "Ажлын санах ой",
            },
            text: raw.generalIntelligence.txt,
          };

          const openness = {
            adaptability: {
              value: raw.openness.adaptability,
              rs: "Адаптивность",
              en: "Adaptability",
              mn: "Дасан зохицох чадвар",
            },
            curiosity: {
              value: raw.openness.curiosity,
              rs: "Любознательность",
              en: "Curiosity",
              mn: "Сониуч зан",
            },
            riskTaking: {
              value: raw.openness.riskTaking,
              rs: "Готовность к риску",
              en: "Risk Taking",
              mn: "Эрсдэл хүлээх чадвар",
            },
            text: raw.openness.txt,
          };

          setReport({
            ...data.data,
            agreeable: agreeable,
            competencies: competencies,
            conscientiousness: conscientiousness,
            generalIntelligence: generalIntelligence,
            openness: openness,
          });

          console.log("report", data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const getColor = (value) => {
    if (value > 45) return "#34D399";
    if (value > 40 && value < 44) return "#F87171";
    return "#FBBF24";
  };

  const truncateLabel = (label, maxLength = 15) => {
    return label.length > maxLength
      ? `${label.substring(0, maxLength)}...`
      : label;
  };

  if (report === null || data === null) {
    return <Loading />;
  }

  const Cdata = {
    labels: label.map((key) => {
      const item = report.generalIntelligence[key];
      const label = item ? item[i18n.language] : key;
      return truncateLabel(label);
    }),
    datasets: [
      {
        label: "General Intelligence",
        data: Object.values(report.generalIntelligence).map(
          (item) => item.value
        ),
        backgroundColor: Object.values(report.generalIntelligence).map((item) =>
          getColor(item.value)
        ),
        borderColor: Object.values(report.generalIntelligence).map((item) =>
          getColor(item.value)
        ),
        barThickness: 10,
        borderWidth: 1,
      },
    ],
  };

  const Cdata2 = {
    labels: label2.map((key) => {
      const item = report.agreeable[key];
      const label = item ? item[i18n.language] : key;
      return truncateLabel(label);
    }),
    datasets: [
      {
        label: "General Intelligence",
        data: Object.values(report.agreeable).map((item) => item.value),
        backgroundColor: Object.values(report.agreeable).map((item) =>
          getColor(item.value)
        ),
        borderColor: Object.values(report.agreeable).map((item) =>
          getColor(item.value)
        ),
        barThickness: 10,
        borderWidth: 1,
      },
    ],
  };

  const Cdata3 = {
    labels: label3.map((key) => {
      const item = report.competencies[key];
      const label = item ? item[i18n.language] : key;
      return truncateLabel(label);
    }),
    datasets: [
      {
        label: "General Intelligence",
        data: Object.values(report.competencies).map((item) => item.value),
        backgroundColor: Object.values(report.competencies).map((item) =>
          getColor(item.value)
        ),
        borderColor: Object.values(report.competencies).map((item) =>
          getColor(item.value)
        ),
        barThickness: 10,
        borderWidth: 1,
      },
    ],
  };

  const Cdata4 = {
    labels: label4.map((key) => {
      const item = report.conscientiousness[key];
      const label = item ? item[i18n.language] : key;
      return truncateLabel(label);
    }),
    datasets: [
      {
        label: "General Intelligence",
        data: Object.values(report.conscientiousness).map((item) => item.value),
        backgroundColor: Object.values(report.conscientiousness).map((item) =>
          getColor(item.value)
        ),
        borderColor: Object.values(report.conscientiousness).map((item) =>
          getColor(item.value)
        ),
        barThickness: 10,
        borderWidth: 1,
      },
    ],
  };

  const Cdata5 = {
    labels: label5.map((key) => {
      const item = report.openness[key];
      const label = item ? item[i18n.language] : key;
      return truncateLabel(label);
    }),
    datasets: [
      {
        label: "General Intelligence",
        data: Object.values(report.openness).map((item) => item.value),
        backgroundColor: Object.values(report.openness).map((item) =>
          getColor(item.value)
        ),
        borderColor: Object.values(report.openness).map((item) =>
          getColor(item.value)
        ),
        barThickness: 10,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const downloadLongImage = async () => {
    const downloadButton = document.querySelectorAll("#downloadButton");
    const sections = document.querySelectorAll(".section");
    const bottomSpaces = document.querySelectorAll(".bottom-Space");

    const bottomSpacing = document.querySelectorAll(".bottom-spacing");

    downloadButton.forEach((button) => {
      button.style.display = "none";
    });

    bottomSpaces.forEach((element) => {
      element.style.marginBottom = "20px";
    });

    bottomSpacing.forEach((element) => {
      element.style.marginRight = "30px";
    });

    const pdf = new jsPDF({
      format: "a4",
    });

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, {
        backgroundColor: "#ffffff",
      });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const scaleFactor = Math.min(
        pdf.internal.pageSize.getWidth() / imgWidth,
        pdf.internal.pageSize.getHeight() / imgHeight
      );

      if (i > 0) {
        pdf.addPage("a4");
      }

      pdf.addImage(
        imgData,
        "JPEG",
        5,
        5,
        imgWidth * scaleFactor,
        imgHeight * scaleFactor
      );
    }

    pdf.save(`${data.name}.pdf`);
    downloadButton.forEach((button) => {
      button.style.display = "flex";
      button.style.justifyContent = "flex-end";
      button.style.marginTop = "10px !important";
    });

    bottomSpaces.forEach((element) => {
      element.style.marginBottom = "0px";
    });

    bottomSpacing.forEach((element) => {
      element.style.marginRight = "0px";
    });
  };

  return (
    <div ref={reportRef} id="reportContainer" className="w-10/12 m-auto pt-11">
      <div>
        <div className="section">
          <div className="flex items-center justify-between mb-10 ">
            <div className="flex items-center gap-4">
              <div className="rounded-full border border-[#ccc] bg-[#fff] w-[80px] h-[80px] flex items-center justify-center">
                <FaUser className="w-[55px] h-[55px] text-[#ccc]" />
              </div>
              {/* <div>
                <p className="text-[#222]">name</p>
                <p className="text-[#222]">email</p>
                <p className="text-[#222]">phoneNumber</p>
              </div> */}
            </div>
            <div className="flex items-center gap-6 bottom-spacing">
              <div className="border rounded-lg bg-[#fff] w-[150px] px-4 py-2">
                <p className="text-[#222] text-lg">MPI {t("Report.score")} :</p>
                <p className="mt-2 text-end text-lg text-[#222] bottom-Space">
                  {Number(data.mpi).toFixed(1)}
                </p>
              </div>
              <div className="border rounded-lg bg-[#fff] w-[150px] px-4 py-2">
                <p className="text-[#222] text-lg">CI {t("Report.score")} :</p>
                <p className="mt-2 text-end text-lg text-[#222] bottom-Space">
                  {Number(data.ci).toFixed(1)}
                </p>
              </div>
            </div>
          </div>
          <div id="downloadButton" className="mb-20 flex justify-end">
            <button
              onClick={downloadLongImage}
              className="px-4 py-2 text-[#fff] bg-[#324d72] rounded-xl hover:bg-opacity-60 flex items-center gap-2"
            >
              {t("assessmentProfile.downloadReport")}
            </button>
          </div>
          <div className="">
            <div>
              <p className="text-semibold bottom-spacing">
                1: {report.generalIntelligence.text}
              </p>
            </div>
            <div className="w-full flex items-start jusitfy-between mt-4">
              <div className="w-[80%]">
                <Bar data={Cdata} options={options} />
              </div>
              <div className="w-[20%] mt-12">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#34D399]" />
                  <p className="bottom-Space">{t("Softskill.Dep.high")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#FBBF24]" />
                  <p className="bottom-Space">{t("Softskill.Dep.mid")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#F87171]" />
                  <p className="bottom-Space">{t("Softskill.Dep.low")}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-10" />
          <div>
            <div>
              <p className="text-semibold bottom-spacing">
                2: {report.agreeable.text}
              </p>
            </div>
            <div className="w-full flex items-start jusitfy-between mt-4">
              <div className="w-[80%]">
                <Bar data={Cdata2} options={options} />
              </div>
              <div className="w-[20%] mt-12">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#34D399]" />
                  <p className="bottom-Space">{t("Softskill.Dep.high")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#FBBF24]" />
                  <p className="bottom-Space">{t("Softskill.Dep.mid")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#F87171]" />
                  <p className="bottom-Space">{t("Softskill.Dep.low")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-10" />
        <div className="section">
          <div>
            <div>
              <p className="text-semibold bottom-spacing">
                3: {report.competencies.text}
              </p>
            </div>
            <div className="w-full flex items-start jusitfy-between mt-4">
              <div className="w-[80%]">
                <Bar data={Cdata3} options={options} />
              </div>
              <div className="w-[20%] mt-12">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#34D399]" />
                  <p className="bottom-Space">{t("Softskill.Dep.high")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#FBBF24]" />
                  <p className="bottom-Space">{t("Softskill.Dep.mid")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#F87171]" />
                  <p className="bottom-Space">{t("Softskill.Dep.low")}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-10" />
          <div>
            <div>
              <p className="text-semibold bottom-spacing">
                4: {report.conscientiousness.text}
              </p>
            </div>
            <div className="w-full flex items-start jusitfy-between mt-4">
              <div className="w-[80%]">
                <Bar data={Cdata4} options={options} />
              </div>
              <div className="w-[20%] mt-12">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#34D399]" />
                  <p className="bottom-Space">{t("Softskill.Dep.high")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#FBBF24]" />
                  <p className="bottom-Space">{t("Softskill.Dep.mid")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#F87171]" />
                  <p className="bottom-Space">{t("Softskill.Dep.low")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-10" />
        <div className="section">
          <div>
            <div>
              <p className="text-semibold bottom-spacing">
                5: {report.openness.text}
              </p>
            </div>
            <div className="w-full flex items-start jusitfy-between mt-4">
              <div className="w-[80%]">
                <Bar data={Cdata5} options={options} />
              </div>
              <div className="w-[20%] mt-12">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#34D399]" />
                  <p className="bottom-Space">{t("Softskill.Dep.high")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#FBBF24]" />
                  <p className="bottom-Space">{t("Softskill.Dep.mid")}</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-lg bg-[#F87171]" />
                  <p className="bottom-Space">{t("Softskill.Dep.low")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftSkillDetail;
