import React, { useEffect, useState } from "react";

import { FaMagnifyingGlass } from "react-icons/fa6";
import SendedFeedback from "./employee/SendedFeedback";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardEmployee from "./employee/OnboardEmployee";
import { getEmpData } from "../../services/employee.service";
import Loading from "../../components/uncommon/Loading";
import { IoIosArrowForward } from "react-icons/io";
import OverviewEmployee from "./employee/OverviewEmployee";
import { useParams } from "react-router-dom";
import { FaArrowUpShortWide } from "react-icons/fa6";
import { FaArrowDownWideShort } from "react-icons/fa6";
import { getUserInfo } from "../../services/user.service";
import { MdBarChart } from "react-icons/md";
import TotalCompany from "./employee/TotalCompany";
import { useTranslation } from "react-i18next";

const Workers = () => {
  const { id: routeId } = useParams();
  const height = window.innerHeight - 120;
  const [activeTable, setActiveTable] = useState("total");
  const [employees, setEmployees] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [hover, setHover] = useState(0);
  const [id, setId] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [sortOrder, setSortOrder] = useState("");
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    getEmpData()
      .then((data) => {
        setEmployees(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (routeId) {
      setId(routeId);

      if (location.pathname.includes("report")) {
        setActiveTable("report");
      } else if (location.pathname.includes("onboard")) {
        setActiveTable("onboard");
      }
    }
  }, [routeId, location.pathname]);
  useEffect(() => {
    if (id && employees) {
      const employee = employees.find((emp) => emp.id === id);
      if (employee) {
        setSelectedEmployee(employee);
      }
    }
  }, [id, employees]);

  const handleTableChange = (table) => {
    setActiveTable(table);
    navigate(`/company/employee/${id}/${table}`);
  };
  const renderActiveTable = () => {
    switch (activeTable) {
      case "total":
        return <TotalCompany />;
      case "report":
        return <SendedFeedback id={id} cId={user.companies[0].id} />;
      case "onboard":
        return <OnboardEmployee id={id} />;
      case "overview":
        return <OverviewEmployee data={selectedEmployee} />;
      default:
        return null;
    }
  };

  const sortEmployees = (employees, order) => {
    return [...employees].sort((a, b) => {
      if (order === "asc") {
        return (a.feedBackCount || 0) - (b.feedBackCount || 0);
      } else if (order === "desc") {
        return (b.feedBackCount || 0) - (a.feedBackCount || 0);
      }
    });
  };

  const filterEmployees = (employees) => {
    const filtered = employees.filter((employee) =>
      `${employee.firstName} ${employee.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    return sortEmployees(filtered, sortOrder);
  };

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  if (employees === null || user === null) {
    return <Loading />;
  }

  return (
    <div className="w-full flex">
      <div className="w-[20%] border-r border-[#E2E8F0] px-1 py-2">
        <div className="flex relative">
          <div className="absolute top-1/2 left-3 transform  -translate-y-1/2 z-10">
            <FaMagnifyingGlass className="text-[#64748B] opacity-100" />
          </div>
          <input
            className="rounded-xl text-sm  w-full bg-[#Fff] border-none ps-8"
            placeholder={t("Employee.empName")}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* <div className="mt-4 pt-2 flex items-center justify-between gap-2 w-full overflow-x-auto">
          <div className="relative min-w-[200px]">
            <select
              id="example-input-1"
              className="peer h-10 w-full border-b-2 border-gray-300 focus:outline-none focus:border-blue-500 placeholder-transparent text-xs rounded-lg p-1"
              onChange={(e) => setSelected(!!e.target.value)}
            >
              <option defaultValue value=""></option>
              <option value="123">1123</option>
            </select>
            <label
              htmlFor="example-input-1"
              className={`absolute left-3 top-2 rounded-lg bg-white px-0.5 text-xs text-[#222] transition-all duration-200 ease-in-out 
          ${
            selected
              ? "-top-2 text-xs text-blue-500"
              : "peer-placeholder-shown:top-2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-[#222] peer-focus:-top-2 peer-focus:text-xs peer-focus:text-blue-500"
          }`}
            >
              Enter your text
            </label>
          </div>
        </div> */}

        <div style={{ height: `${height}px` }}>
          <button
            onClick={() => {
              setId(null);

              setActiveTable("total");
            }}
            className={`flex items-center gap-1 text-[#1E293B] ${
              id === null ? "bg-[#EEF2FF]" : "bg-[#fff]"
            } border border-[#AAB0C5] rounded-md text-xs w-full py-1 px-2 mt-4 mb-2`}
          >
            {" "}
            <MdBarChart />
            {t("Employee.allStat")}
          </button>
          {employees.length !== 0 && (
            <div>
              <div className="flex items-center justify-between">
                <p className="text-[#293951] text-sm mb-2">
                  {t("Employee.mainEmp")}
                </p>
                {activeTable === "report" && (
                  <button
                    className="me-2 p-2 text-[#64748B] transition-transform duration-300 ease-in-out transform hover:scale-105"
                    onClick={handleSort}
                  >
                    {sortOrder === "asc" ? (
                      <FaArrowUpShortWide className="transform transition-transform duration-300 ease-in-out" />
                    ) : (
                      <FaArrowDownWideShort className="transform transition-transform duration-300 ease-in-out" />
                    )}
                  </button>
                )}
              </div>

              <div className={`max-h-[75vh] overflow-y-scroll`}>
                {filterEmployees(employees).map((items) => {
                  return (
                    <div
                      key={items.id}
                      onClick={() => {
                        setHover(0);
                        setId(items.id);
                        setSelectedEmployee(items);
                        if (activeTable === "total") {
                          setActiveTable("overview");
                        }
                      }}
                      onMouseEnter={() => {
                        if (id !== items.id) {
                          setHover(items.id);
                        }
                      }}
                      onMouseLeave={() => {
                        setHover(0);
                      }}
                      className={`flex items-center justify-between gap-2 border cursor-pointer  ${
                        id === items.id
                          ? "bg-[#EEF2FF] border-[#AAB0C5]"
                          : "bg-[#fff] border-[#fff]"
                      } p-2 mb-2 rounded-xl hover:bg-[#F3F4F8]`}
                    >
                      <div className="flex items-center gap-2">
                        <div className="w-[30px] h-[30px] flex items-center justify-center rounded-full border border-[#cfcfcf]">
                          <img
                            src={items.profileUrl}
                            className="object-cover w-full h-full rounded-full"
                            alt="profile"
                          />
                        </div>
                        <div>
                          <p className="text-xs text-[#1E293B] font-semibold">
                            {items.lastName} {items.firstName}
                          </p>
                          <p className="text-[#64748B] text-xs">
                            {items.position}
                          </p>
                        </div>
                      </div>
                      <div className="w-[20px]">
                        {activeTable === "report" ? (
                          items.feedBackCount === null ? (
                            0
                          ) : (
                            items.feedBackCount
                          )
                        ) : (
                          <div>
                            {hover === items.id && (
                              <IoIosArrowForward className="text-xl text-[#2C4360] opacity-70" />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-[80%] px-4">
        {activeTable === "total" ? (
          ""
        ) : (
          <div className="border-b flex items-center gap-4 pt-2 pb-4">
            <button onClick={() => handleTableChange("overview")}>
              <p
                className={` text-sm ${
                  activeTable === "overview"
                    ? "text-[#324D72] border-b border-[#324D72]"
                    : "text-[#666874]"
                }`}
              >
                {t("Employee.overview")}
              </p>
            </button>
            {user.role === "ADMIN" || user.role === "MANAGER" ? (
              <button onClick={() => handleTableChange("report")}>
                <p
                  className={` text-sm ${
                    activeTable === "report"
                      ? "text-[#324D72] border-b border-[#324D72]"
                      : "text-[#666874]"
                  }`}
                >
                  {user.companies[0].id === 31
                    ? t("Employee.feedforward")
                    : t("Employee.feedback")}
                </p>
              </button>
            ) : (
              ""
            )}
            <button onClick={() => handleTableChange("onboard")}>
              <p
                className={`${
                  activeTable === "onboard"
                    ? "text-[#324D72] border-b border-[#324D72]"
                    : "text-[#666874]"
                } text-sm`}
              >
                {t("Employee.onboarding")}
              </p>
            </button>
            {/* <button onClick={() => handleTableChange("")}>
            <p className={`text-[#666874] text-sm`}>Survey</p>
          </button> */}
          </div>
        )}
        <div>{renderActiveTable()}</div>
      </div>
    </div>
  );
};
export default Workers;
