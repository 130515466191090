import React, { useEffect, useRef, useState } from "react";
import MeetingInfo from "./MeetingInfo";
import MeetingTable from "./MeetingTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import { getMeeting } from "../../../services/meeting.service";
import Loading from "../../../components/uncommon/Loading";
import { FaRegCopy } from "react-icons/fa6";
import { MdOutlineInsertLink } from "react-icons/md";
import { QRCodeCanvas } from "qrcode.react";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const Meeting = () => {
  const [page, setPage] = useState("table");
  const [data, setData] = useState(null);
  const { id } = useParams();
  const location = useLocation();
  const { location: itemLocation, duration } = location.state || {};
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const qrCanvasRef = useRef(null);
  const modalRef = useRef();
  const { t } = useTranslation();

  const handleModalClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setPopUp(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(data.url)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const downloadQRCode = () => {
    const canvas = qrCanvasRef.current;
    if (canvas) {
      const imageUrl = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = imageUrl;
      link.download = "QRCode.png";
      link.click();
    } else {
      console.error("QR code canvas not found.");
    }
  };

  useEffect(() => {
    getMeeting(id)
      .then((data) => setData(data.data))
      .catch((err) => console.log(err));
  }, [id]);

  if (data === null) {
    return <Loading />;
  }

  const handleChangePage = (page) => {
    setPage(page);
    navigate(`${page}`);
  };

  const renderActivePage = () => {
    switch (page) {
      case "table":
        return <MeetingTable data={data} />;
      case "info":
        return (
          <MeetingInfo
            data={data}
            location={itemLocation}
            duration={duration}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="pt-11 w-10/12 m-auto">
      {console.log(data)}
      <div className="mb-4">
        <button
          onClick={() => navigate("/company/meetings")}
          className="flex items-center gap-2 text-[#1E293B] text-sm"
        >
          <FaArrowLeft />
          {t("Meetings.report.back")}
        </button>
      </div>
      <div className="flex items-center justify-between mb-10">
        <p className="text-[24px] text-[#222]">{data.name}</p>
        {/* <div className="flex items-end gap-8">
          <button
            className={`pb-2 text-sm ${
              page === "table"
                ? "text-[#2C4360] border-b-2 border-[#2C4360]"
                : "text-[#666874]"
            }`}
            onClick={() => {
              handleChangePage("table");
            }}
          >
            Уулзалт товлосон хүмүүс
          </button>
          <button
            className={`pb-2 text-sm ${
              page === "info"
                ? "text-[#2C4360] border-b-2 border-[#2C4360]"
                : "text-[#666874]"
            }`}
            onClick={() => {
              handleChangePage("info");
            }}
          >
            Уулзалтын мэдээлэл
          </button>
        </div> */}
        <div className="w-[163px] h-[2px]"></div>
      </div>
      <div className="mb-4 flex justify-between items-center">
        <div className="flex items-cetner gap-8">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => window.open(`https://${data.url}`, "_blank")}
          >
            <MdOutlineInsertLink />
            <p className="text-[#222] text-sm">{data.url}</p>
          </div>

          <button
            onClick={handleCopy}
            className="text-[#222] flex items-center gap-2"
          >
            <FaRegCopy />
            {isCopied && (
              <span className="text-green-500 text-xs">
                {t("Meetings.create.copied")}
              </span>
            )}
          </button>
        </div>
        <button
          onClick={() => {
            setPopUp(true);
          }}
          className="px-3 py-2 text-[#fff] bg-[#324d72] rounded-xl text-sm"
        >
          {t("Meetings.report.seeqr")}
        </button>
      </div>
      <div>{renderActivePage()}</div>
      {popUp && (
        <div
          onClick={handleModalClickOutside}
          className="flex items-center justify-center fixed inset-0 h-full w-full bg-[#000] bg-opacity-40 z-50"
        >
          <div ref={modalRef} className="bg-[#fff] rounded-xl p-6">
            <div className="flex items-center justify-between mb-6">
              <p className="text-[#222] text-2xl font-semibold">
                {t("Meetings.report.qr")}
              </p>
              <button
                onClick={() => {
                  setPopUp(false);
                }}
                className="text-2xl "
              >
                <IoMdClose />
              </button>
            </div>
            <div className="flex items-center justify-center">
              <QRCodeCanvas ref={qrCanvasRef} value={data.url} size={300} />
            </div>
            <div className="mt-6 flex justify-end">
              <button
                onClick={downloadQRCode}
                className="px-3 py-2 bg-[#324d72] text-[#fff] rounded-lg text-sm"
              >
                {t("Meetings.report.down")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Meeting;
