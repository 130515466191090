import { useEffect } from "react";
import { loginByAzureOauth } from "../services/user.service";
import { azureConfig } from "./../helper/authConfig";
import Cookies from "js-cookie";


const handleAuthCallback = async () => {
    console.log("Started")
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const codeVerifier = sessionStorage.getItem("pkce_code_verifier");
    if (code && codeVerifier) {
        try {
            console.log("Started1")

            // Exchange authorization code for an access token
            const tokenResponse = await fetch(`${azureConfig.authority}/oauth2/v2.0/token`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                    client_id: azureConfig.clientId,
                    grant_type: "authorization_code",
                    code,
                    redirect_uri: azureConfig.redirectUri,
                    code_verifier: codeVerifier,
                }),
            });
            console.log("Started2")

            const tokenData = await tokenResponse.json();

            if (tokenData.access_token) {
                console.log("Started3")

                // Fetch user email using the Microsoft Graph API
                const email = await fetchUserEmail(tokenData.access_token);

                if (email) {
                    console.log("Started4")

                    // Call your custom login function with the fetched email
                    const login = await loginByAzureOauth(email);

                    if (login?.data?.accessToken) {
                        console.log("Started5")

                        console.log("Backend Access Token:", login.accessToken);
                        // Store both Azure and backend tokens
                        localStorage.setItem("azure_access_token", tokenData.access_token);
                        localStorage.setItem("backend_access_token", login.data.accessToken);
                        Cookies.set("access_token", login.data.accessToken, { expires: 1 });

                        // Redirect to the desired page
                        window.location.href = "/";
                    }
                }
            } else {
                console.error("Token exchange failed:", tokenData);
                window.location.href = "/";
            }
        } catch (error) {
            console.error("Error during authentication:", error);
            window.location.href = "/";
        }
    }
};
// Function to fetch user email using Microsoft Graph API
const fetchUserEmail = async (accessToken) => {
    try {
        const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
            method: "GET",
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });

        const userData = await graphResponse.json();
        return userData.mail || userData.userPrincipalName; // Return the email or username
    } catch (error) {
        console.error("Error fetching user email:", error);
        return null;
    }
};

const Oauth2Login = () => {
    useEffect(() => {

        handleAuthCallback();

    }, [])
    return <div>Hello</div>
}

export default Oauth2Login