import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Transition from "../../utils/Transition";
import UserAvatar from "../../images/user-avatar-32.png";
import { getCompanyInfo } from "../../services/player.service";
import { useTranslation } from "react-i18next";
import { Avatar } from "@material-tailwind/react";
import { useUserContext } from "../../context/UserContext";
import { BiSolidUser } from "react-icons/bi";

function UserMenu() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { user, setUser } = useUserContext();

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    // getCompanyInfo().then((val) => {
    //   const { data } = val;
    //   setCompany(data.name);
    // });
  }, []);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <div className="flex items-center truncate">
          {/* <Avatar src="/assets/wAvatar.png" color="red" size="sm"/> */}
          <div>
            {user?.photoUrl ? (
              <img
                className="h-10 w-10 rounded-full"
                src={user?.photoUrl}
                alt="user"
              />
            ) : (
              <div className="h10 w-10 border rounded-full flex items-center justify-center">
                <BiSolidUser className="m-0 cursor-pointer w-[30px] h-[30px] text-[#666874]" />
              </div>
            )}
          </div>
          <div>
            <p className="truncate ml-2  font-bold">
              {user?.name || ""} {user?.lastName || ""}
            </p>
            <p className="truncate ml-2 text-sm text-[#6C6C72]">
              {user?.userRole || ""} @ {user?.companyName || ""}
            </p>
          </div>

          <svg
            className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            <li>
              <Link
                className="text-[17px] text-sm text-main-blue hover:text-indigo-600 flex items-center py-1 px-3"
                to="/settings/profile"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                {t("Settings.myInfo")}
              </Link>
            </li>
            <li>
              <Link
                className="text-[17px] text-sm text-main-blue hover:text-indigo-600 flex items-center py-1 px-3"
                to="/"
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                  Cookies.remove("access_token");
                  window.location.reload();
                }}
              >
                {t("Settings.exit")}
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default UserMenu;
