import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight, FaPlus } from "react-icons/fa6";
import { getCompanyOfficialEmployee } from "../../../services/employee.service";
import { exportCreateEmployees } from "../../../services/employee.service";
import { CircularProgress } from "@mui/material";
import Loading from "../../../components/uncommon/Loading";
import { useNavigate } from "react-router-dom";
import { SiMicrosoftexcel } from "react-icons/si";
import { IoCloudUploadOutline } from "react-icons/io5";
import Modal from "@mui/material/Modal";
import { toast, ToastContainer } from "react-toastify";
import { getStruct } from "../../../services/employee.service";

const TotalCompany = () => {
  const { t } = useTranslation();
  const [emp, setEmp] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [sending, setSending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const totalPages = Math.ceil(emp.length / itemsPerPage);

  const [struct, setStruct] = useState(null);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    getCompanyOfficialEmployee()
      .then((data) => setEmp(data.data))
      .catch((err) => console.log("err", err));
    getStruct()
      .then((data) => {
        setStruct(data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
    setLoading(false);
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleDownloadExcel = async () => {
    try {
      const response = await fetch("/excel/EmployeesExcelFileTemplate.xlsx");
      const blob = await response.blob();

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "EmployeesExcelFileTemplate.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(selectedFile);
      } else {
        setFile(null);
        toast.error(t("toast.selectExcelFile"));
      }
    }
  };

  const filteredItems = emp
    .filter((items) =>
      `${items.lastName} ${items.firstName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .filter((items) => {
      if (selectedDepartment && selectedTeam) {
        return (
          items.department === selectedDepartment && items.team === selectedTeam
        );
      } else if (selectedDepartment) {
        return items.department === selectedDepartment;
      } else if (selectedTeam) {
        return items.team === selectedTeam;
      } else {
        return true;
      }
    });

  const currentItems = filteredItems.slice(firstIndex, lastIndex);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-11/12 m-auto pt-10">
      <div className="flex items-center justify-end gap-4">
        <button
          onClick={handleOpen}
          className="bg-[#00A865] py-1.5 px-3 flex items-center gap-2 text-[#fff] text-sm rounded-xl hover:opacity-60"
        >
          {t("EmployeeTable.excel")}
        </button>{" "}
        <button
          onClick={() => {
            navigate("/company/employee/add");
          }}
          className="bg-[#324D72] py-1.5 px-3 flex items-center gap-2 text-[#fff] text-sm rounded-xl hover:opacity-60"
        >
          <FaPlus />
          {t("EmployeeTable.employeeAddButton")}
        </button>
      </div>
      <div className="bg-[#fff] shadow-custom rounded-xl px-6 py-3 mt-10">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-[#293951]">{t("OrgChart.title")}</p>
            <p className="text-[#293951] text-xs">
              {emp.length} {t("EmployeeTable.howMuchEmployee")}
            </p>
          </div>
          <div className="flex items-center gap-4">
            <select
              className="text-xs text-[#222] px-2 py-1 border border-[#ECECEC] rounded-md pe-6 w-[150px]"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option value="">{t("Employee.dep")}</option>
              {struct?.departments.map((items) => (
                <option key={items.id} value={items.name}>
                  {items.name}
                </option>
              ))}
            </select>
            <select
              className="text-xs text-[#222] px-2 py-1 border border-[#ECECEC] rounded-md pe-6"
              value={selectedTeam}
              onChange={(e) => setSelectedTeam(e.target.value)}
            >
              <option value="">{t("Employee.team")}</option>
              {struct?.teams.map((items) => (
                <option key={items.id} value={items.name}>
                  {items.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mt-4">
          <div className="mb-4">
            <input
              type="text"
              className="border border-gray-300 rounded-md p-2 text-sm"
              placeholder={t("Employee.searchName")}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <table className="w-full">
            <thead className="py-2">
              <tr>
                {" "}
                <th className="ps-4 text-[#1E293B] text-sm py-2">
                  {t("EmployeeTable.tableSurname")}
                </th>
                <th className="ps-4 text-[#1E293B] text-sm py-2">
                  {t("EmployeeTable.tableDepartment")}
                </th>
                <th className="ps-4 text-[#1E293B] text-sm py-2">
                  {t("EmployeeTable.tableTeam")}
                </th>
                <th className="ps-4 text-[#1E293B] text-sm py-2">
                  {t("EmployeeTable.tablePosition")}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((items, index) => (
                <tr
                  key={index}
                  className={` ${index % 2 === 0 ? "bg-[#F5F5F6]" : ""}`}
                >
                  <td className="py-2 ps-4">
                    <p className="text-[#324D72] text-sm font-semibold">
                      {items.lastName} {items.firstName}
                    </p>
                  </td>
                  <td className="py-2 ps-4">
                    <p className="text-[#324D72] text-sm ">
                      {items.department}
                    </p>
                  </td>
                  <td className="py-2 ps-4">
                    <p className="text-[#324D72] text-sm ">{items.team}</p>
                  </td>
                  <td className="py-2 ps-4">
                    <p className="text-[#324D72] text-sm ">{items.position}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex items-center justify-end gap-4 mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <div className="flex gap-2">
              {Array.from({ length: totalPages }).map((_, index) => {
                const pageNumber = index + 1;

                if (
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  (pageNumber >= currentPage - 1 &&
                    pageNumber <= currentPage + 1)
                ) {
                  return (
                    <button
                      key={pageNumber}
                      className={`text-sm text-[#000] ${
                        pageNumber === currentPage ? " font-bold" : "opacity-70"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  );
                }

                if (pageNumber === 2 && currentPage > 3) {
                  return (
                    <span key="dots-1" className="px-2">
                      ...
                    </span>
                  );
                }

                if (
                  pageNumber === totalPages - 1 &&
                  currentPage < totalPages - 2
                ) {
                  return (
                    <span key="dots-2" className="px-2">
                      ...
                    </span>
                  );
                }

                return null;
              })}
            </div>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div className="absolute top-1/2 left-1/2 w-[650px]  rounded-xl transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 shadow-xl">
          <p className="text-xl text-bold">
            {" "}
            {t("EmployeeTable.usingExcelToAddMany")}
          </p>
          <div className="flex justify-between items-center mt-4">
            <p className="text-lg text-[#324d72]">
              {t("EmployeeTable.etcExcel")}
            </p>
            <button
              onClick={handleDownloadExcel}
              className="flex items-center text-white bg-[#55D6A0] opacity-100 py-2 px-3 rounded-lg me-2 hover:opacity-50 gap-2"
            >
              <SiMicrosoftexcel />
              {t("EmployeeTable.download")}
            </button>
          </div>
          <p className="text-xl font-bold mt-7">
            {t("EmployeeTable.ChooseWorkerExcel")}
          </p>
          <div className="flex justify-between items-center mt-4">
            {file === null ? (
              <p className="p-2 border-black border-2 rounded-lg w-full me-5 italic">
                {t("EmployeeTable.ChooseExcel")}
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  onChange={handleFileInputChange}
                />
              </p>
            ) : (
              <p className="p-2 border-black border-2 rounded-lg w-full me-5 italic">
                {file.name}
              </p>
            )}
            <button className="border-2 p-1 rounded-full border-[#324d72] text-[#324d72] hover:bg-[#324d72]  hover:text-white">
              <label htmlFor="fileInput" className="cursor-pointer">
                <FaPlus className="h-7 w-7" />
              </label>
              <input
                id="fileInput"
                type="file"
                className="hidden"
                accept=".xlsx, .xls"
                onChange={handleFileInputChange}
              />
            </button>
          </div>
          <div className="w-full flex justify-end mt-6">
            <button
              // disabled={sending}
              onClick={async () => {
                if (file === null) {
                  toast.error(t("toast.addExcelFile"));
                  return;
                }
                if (sending === false) {
                  const formData = new FormData();
                  formData.append("file", file);
                  setSending(true);
                  try {
                    const response = await exportCreateEmployees(formData);
                    if (response.status === 200) {
                      toast.success(t("toast.successfulSent"));
                    } else {
                      toast.error(t("toast.excelFailed"));
                    }
                  } catch (error) {
                    console.error("Upload failed", error);
                    toast.error(t("toast.excelFailed"));
                  }
                  setSending(false);
                }
              }}
              className="text-lg px-6 py-2 rounded-lg text-white bg-[#324d72] hover:opacity-50"
            >
              {sending ? (
                <CircularProgress size={25} />
              ) : (
                <div className="flex items-center gap-2">
                  <IoCloudUploadOutline />
                  {t("EmployeeTable.add")}
                </div>
              )}
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default TotalCompany;
