import { FaPlus } from "react-icons/fa6";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { MdOutlineLocalPhone } from "react-icons/md";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { FiMail } from "react-icons/fi";

const CandidatesData = [
  {
    id: 1,
    name: "John Doe",
    phone: "+1 555-1234",
    profession: "Software Engineer",
    gmail: "johndoe@gmail.com",
    daysBefore: 3,
    img: "https://randomuser.me/api/portraits/men/1.jpg",
    matchesPercent: 87,
  },
  {
    id: 2,
    name: "Jane Smith",
    phone: "+1 555-5678",
    profession: "Graphic Designer",
    gmail: "janesmith@gmail.com",
    daysBefore: 7,
    img: "https://randomuser.me/api/portraits/women/2.jpg",
    matchesPercent: 92,
  },
  {
    id: 3,
    name: "Michael Johnson",
    phone: "+1 555-9876",
    profession: "Data Analyst",
    gmail: "michaelj@gmail.com",
    daysBefore: 2,
    img: "https://randomuser.me/api/portraits/men/3.jpg",
    matchesPercent: 75,
  },
  {
    id: 4,
    name: "Emily Davis",
    phone: "+1 555-4321",
    profession: "Marketing Manager",
    gmail: "emilydavis@gmail.com",
    daysBefore: 5,
    img: "https://randomuser.me/api/portraits/women/4.jpg",
    matchesPercent: 88,
  },
  {
    id: 5,
    name: "David Martinez",
    phone: "+1 555-2468",
    profession: "Financial Analyst",
    gmail: "davidm@gmail.com",
    daysBefore: 1,
    img: "https://randomuser.me/api/portraits/men/5.jpg",
    matchesPercent: 80,
  },
];

const TaskCard = ({ item, index }) => {
  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="w-full bg-white p-4 border border-[#F4F6FB] rounded-xl mb-3"
        >
          <div className="flex items-center gap-2 mb-3">
            <div className="border border-[#E5E6E8] w-[40px] h-[40px] rounded-full">
              <img
                className="w-full h-full object-contain rounded-full"
                src={item.img}
                alt="avatar"
              />
            </div>
            <div>
              <p className="text-[#1E293B] text-sm font-semibold">
                {item.name}
              </p>
              <p className="text-[#1E293B] text-xs">{item.profession}</p>
            </div>
          </div>
          <div className="mb-3">
            <div className="flex items-center text-[#1E293B] text-sm">
              <MdOutlineLocalPhone />
              <p className="ms-2">{item.phone}</p>
            </div>
            <div className="flex items-center text-[#1E293B] text-sm">
              <FiMail />
              <p className="ms-2">{item.gmail}</p>
            </div>
          </div>
          <div className="mb-3 flex gap-3 items-center">
            <p className="text-[#777985] text-[10px]">{item.matchesPercent}%</p>
            <div className="w-[80%]">
              <div className="w-full bg-green-200 rounded-lg h-3">
                <div
                  className="bg-green-500 h-3 rounded-lg transition-all duration-300"
                  style={{ width: `${item.matchesPercent}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#DFE8F1] h-[2px] rounded-xl mb-3" />
          <div className="flex items-center justify-between">
            <p className="text-[#777985] text-xs">
              {item.daysBefore} өдрийн өмнө
            </p>
            <button className="py-1 px-3 text-white bg-[#324d72] rounded-lg text-sm border border-[#CECFD3]">
              CV харах
            </button>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const ResumeBoard = () => {
  const [columns, setColumns] = useState({
    column1: {
      title: "To Do",
      items: CandidatesData,
    },
    column2: {
      title: "In Progress",
      items: [],
    },
    column3: {
      title: "Done",
      items: [],
    },
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    setColumns((prevColumns) => {
      const updatedColumns = { ...prevColumns };
      const sourceColumn = updatedColumns[source.droppableId];
      const destColumn = updatedColumns[destination.droppableId];

      if (!sourceColumn || !destColumn) return prevColumns;

      const sourceItems = Array.from(sourceColumn.items);
      const [movedItem] = sourceItems.splice(source.index, 1);

      if (source.droppableId !== destination.droppableId) {
        const destItems = Array.from(destColumn.items);
        destItems.splice(destination.index, 0, movedItem);
        updatedColumns[source.droppableId].items = sourceItems;
        updatedColumns[destination.droppableId].items = destItems;
      } else {
        sourceItems.splice(destination.index, 0, movedItem);
        updatedColumns[source.droppableId].items = sourceItems;
      }

      return updatedColumns;
    });
  };

  return (
    <div className="p-4 m-auto">
      <div className="w-full rounded-xl bg-white py-4 px-6">
        <div className="flex items-center justify-between mb-8">
          <p className="text-[24px] text-[#222]">Цахилгааны инженер</p>
          <button className="px-6 py-3 flex items-center gap-2 rounded-xl text-sm text-white bg-[#324d72] hover:bg-opacity-50">
            Шинээр зар үүсгэх
          </button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <div className="flex">
            {Object.entries(columns).map(([columnId, column]) => (
              <Droppable key={String(columnId)} droppableId={String(columnId)}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-1/3"
                  >
                    <div
                      className={`${
                        columnId + 1 === Object.entries(columns).length
                          ? "border-none"
                          : "border-e border-[#E5E6E8]"
                      } ps-3 pe-3`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="font-bold text-[#1E293B] mb-2">
                          {column.title}
                        </p>

                        {columnId + 1 === Object.entries(columns).length && (
                          <div className="rounded-lg p-4 bg-[#F4F4F4]">
                            <FaPlus className="text-[#707070]" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="bg-[#E5E6E8] w-full h-[1px] my-4" />
                    <div
                      className={`${
                        columnId + 1 === Object.entries(columns).length
                          ? "border-none"
                          : "border-e border-[#E5E6E8] pe-3"
                      } h-[67vh] overflow-x-scroll`}
                    >
                      {column.items.map((item, index) => (
                        <TaskCard key={item.id} item={item} index={index} />
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ResumeBoard;
