import React, { useEffect, useRef, useState } from "react";
import Loading from "../../../components/uncommon/Loading";
import { useParams } from "react-router-dom";

import {
  declinePlayer,
  getPlayer,
} from "../../../services/assessmentPlayer.service";
import { Bar, Pie } from "react-chartjs-2";
import { TfiSave } from "react-icons/tfi";
import { AiOutlinePrinter } from "react-icons/ai";
import { SlInfo } from "react-icons/sl";
import NormalDistributionChart from "../../../components/uncommon/normalDistributionChart";
import { getPlayerReport } from "../../../services/game.service";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import "../../../index.scss";

const HoverComponent = ({ hoverText, top, left, svg, textStyle }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SlInfo size={20} />

      {isHovering && (
        <div style={{ position: "absolute", top, left }} className=" z-30">
          <div className="relative">
            {svg}
            <p className={`absolute z-50  text-center ${textStyle}`}>
              {hoverText}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const NewGameData = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [report, setReport] = useState(null);
  const [dataDone, setDataDone] = useState(false);
  const [reportDone, setReportDone] = useState(false);
  const { t, i18n } = useTranslation();
  const [mostNeed, setMostNeed] = useState([]);
  const [doesntplay, setDoesntPlay] = useState(false);

  const skills = [
    "curiosity",
    "riskTaking",
    "adaptability",
    "cooperative",
    "trusting",
    "assertiveness",
    "consistence",
    "planning",
  ];

  useEffect(() => {
    if (id) {
      getPlayer(id)
        .then((data) => {
          setData(data.data[0]);
          console.log("data", data.data);
          setDataDone(true);
        })
        .catch((err) => {
          setDoesntPlay(true);
        });
      getPlayerReport(id)
        .then((data) => {
          const updatedSkillDetails = data.data.skillDetails.map((skill) => ({
            ...skill,
            enName: formatSkillName(skill.name),
          }));

          const updatedTop3Skills = data.data.top3Skills.map((skill) => ({
            ...skill,
            enName: formatSkillName(skill.name),
          }));

          const updatedBottom3Skills = data.data.bottom3Skills.map((skill) => ({
            ...skill,
            enName: formatSkillName(skill.name),
          }));

          setReport({
            ...data.data,
            skillDetails: updatedSkillDetails,
            top3Skills: updatedTop3Skills,
            bottom3Skills: updatedBottom3Skills,
          });
          console.log("report", data.data);
          setReportDone(true);
        })
        .catch((err) => {
          setDoesntPlay(true);
          console.log(err);
        });
    }
  }, [id]);

  useEffect(() => {
    if (report) {
      const filteredSkillsArray = report.skillDetails?.filter((s) => {
        return s.expectedLevel === 5;
      });
      setMostNeed(filteredSkillsArray);
    }
  }, [report]);

  const formatSkillName = (name) =>
    name
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();

  const scrollToTop = () => {
    document.documentElement.scrollTop = 20;
  };

  const longImageRef = useRef(null);

  const downloadLongImage = async () => {
    const downloadButton = document.querySelectorAll("#downloadButton");
    const sections = document.querySelectorAll(".section");
    const bottomSpaces = document.querySelectorAll(".bottom-Space");
    const bottomPercent = document.querySelectorAll(".bottom-percent");
    const bottomSpacing = document.querySelectorAll(".bottom-spacing");

    downloadButton.forEach((button) => {
      button.style.display = "none";
    });

    bottomSpaces.forEach((element) => {
      element.style.marginBottom = "20px";
    });

    bottomPercent.forEach((element) => {
      element.style.marginTop = "-20px";
    });

    bottomSpacing.forEach((element) => {
      element.style.marginBottom = "10px";
    });

    const pdf = new jsPDF({
      format: "a4",
    });

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];

      const canvas = await html2canvas(section, { backgroundColor: "#ffffff" });
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      const scaleFactor = Math.min(
        pdf.internal.pageSize.getWidth() / imgWidth,
        pdf.internal.pageSize.getHeight() / imgHeight
      );

      if (i > 0) {
        pdf.addPage("a4");
      }

      pdf.addImage(
        imgData,
        "JPEG",
        5,
        5,
        imgWidth * scaleFactor,
        imgHeight * scaleFactor
      );
    }

    pdf.save(`${data.name}.pdf`);
    downloadButton.forEach((button) => {
      button.style.display = "flex";
      button.style.justifyContent = "flex-end";
      button.style.marginTop = "10px !important";
    });

    bottomSpaces.forEach((element) => {
      element.style.marginBottom = "0px";
    });

    bottomPercent.forEach((element) => {
      element.style.marginTop = "0px";
    });

    bottomSpacing.forEach((element) => {
      element.style.marginBottom = "0px";
    });
  };

  const handleDownload = () => {
    let sw = window.document.getElementsByClassName("downloadBtn")[0];
    let sw1 = window.document.getElementsByClassName("downloadBtn")[1];
    const tmp = sw.style.display;
    const tmp1 = sw1.style.display;

    sw.style.display = "none";
    sw1.style.display = "none";

    const element = window.document.getElementsByClassName("reportDownload")[0];
    if (element) {
      html2pdf()
        .from(element)
        .save(`${data.name}.pdf`)
        .then(() => {
          // Restore display after PDF is generated and saved
          sw.style.display = tmp;
          sw1.style.display = tmp1;
        })
        .catch((error) => console.error("Error saving PDF:", error));
    } else {
      console.error('Element with class "reportDownload" not found.');
      // Restore display if reportDownload element not found
      sw.style.display = tmp;
      sw1.style.display = tmp1;
    }
  };

  // const downloadLongImage = async () => {
  //   const container = longImageRef.current;

  //   // Get the dimensions of the HTML content
  //   const width = container.offsetWidth;
  //   const height = container.offsetHeight;

  //   // Set background color to white before converting to image
  //   container.style.backgroundColor = "white";

  //   // Use html2canvas to capture the container as an image
  //   const canvas = await html2canvas(container, { backgroundColor: null });
  //   const imgData = canvas.toDataURL('image/jpeg');

  //   // Calculate the page size and scale factor
  //   const pdf = new jsPDF({
  //     orientation: 'portrait',
  //     unit: 'px',
  //     format: 'a4'
  //   });

  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const pageHeight = pdf.internal.pageSize.getHeight();
  //   const imgWidth = pageWidth;
  //   const imgHeight = (height * pageWidth) / width;

  //   // Calculate the number of pages needed
  //   let position = 0;
  //   const pages = Math.ceil(imgHeight / pageHeight);

  //   // Add each page to the PDF
  //   for (let i = 0; i < pages; i++) {
  //     if (i > 0) {
  //       pdf.addPage();
  //     }
  //     pdf.addImage(
  //       imgData,
  //       'JPEG',
  //       0,
  //       position,
  //       imgWidth,
  //       imgHeight
  //     );
  //     position -= pageHeight;
  //   }

  //   // Save the PDF document
  //   pdf.save(`${data.name}.pdf`);

  //   // Reset background color after downloading image
  //   container.style.backgroundColor = '';
  // };

  if (doesntplay) {
    return (
      <div className="w-10/12 m-auto pt-11 ">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            <img
              className="w-[100px] h-[100px] border-2 rounded-full border-black "
              src="/user.svg"
              alt="user"
            />
            <div>
              <p className="text-2xl font-bold">
                {data?.lastName} {data?.name}
              </p>
              <p className="text-xl my-4">{data?.assessmentName}</p>
              <div>
                <div className="flex items-center gap-5">
                  <p>Утас:</p>
                  <p>{data?.phoneNumber}</p>
                </div>
                <div className="flex items-center gap-5">
                  <p>Э-шуудан:</p> <p>{data?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="mt-10 text-2xl font-bold"> Тоглогч тоглоогүй байна</p>
      </div>
    );
  }

  if (!dataDone || !reportDone) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const handleDecline = () => {
    declinePlayer(id)
      .then((res) => {
        toast.success(t("assessmentProfile.requestSuccess"));
      })
      .catch((err) => {
        toast.error(t("toast.Error"));
      });
  };

  const findSkill = (name) =>
    report.skillDetails.find((skill) => skill.name === name);

  const getSkillName = (skill) => {
    if (i18n.language === "mn") {
      return skill.mnName;
    } else if (i18n.language === "rn") {
      return skill.ruName;
    } else {
      return skill.enName;
    }
  };

  let totalExpectedLevel = 0;
  skills.forEach((skill) => {
    totalExpectedLevel += findSkill(skill).expectedLevel;
  });

  const totalSkills = skills.length;
  const avgExpectedLevel = totalExpectedLevel / totalSkills;
  const expectedLevelPercent = (avgExpectedLevel / 5) * 100;

  // Sort skills based on expected level
  const sortedSkills = skills.sort(
    (a, b) => findSkill(b).expectedLevel - findSkill(a).expectedLevel
  );

  const top3Skills = sortedSkills
    .slice(0, 3)
    .map((skill) => getSkillName(findSkill(skill)));
  const bottom3Skills = sortedSkills
    .slice(-3)
    .map((skill) => getSkillName(findSkill(skill)));

  const getGradientColor = (value) => {
    const percentage = value * 25;
    return `linear-gradient(to left, #D9D9D9 ${
      100 - percentage
    }%, #66BCCE ${0}%)`;
  };

  const Cdata = {
    labels: mostNeed.map((e) => {
      return getSkillName(e);
    }),
    datasets: [
      {
        label: t("assessmentProfile.playerResult"),
        backgroundColor: "#66BCCE",
        borderColor: "#66BCCE",
        barThickness: 8,
        data: mostNeed.map((e) => {
          return e.level;
        }),
      },
      {
        label: t("assessmentProfile.requiredLevel"),
        backgroundColor: "#CECFD3",
        borderColor: "#CECFD3",
        barThickness: 8,
        data: mostNeed.map((e) => {
          return e.expectedLevel;
        }),
      },
    ],
  };

  const Cdata2 = {
    labels: report.top3Skills.map((item) => {
      if (i18n.language === "mn") {
        return item.mnName;
      } else if (i18n.language === "rn") {
        return item.ruName;
      } else {
        return item.enName;
      }
    }),
    datasets: [
      {
        label: t("total.report.tableHover"),
        backgroundColor: "#324D72",
        data: report.top3Skills.map((item) => item.level),
      },
    ],
  };

  const Cdata3 = {
    labels: report.bottom3Skills.map((item) => {
      if (i18n.language === "mn") {
        return item.mnName;
      } else if (i18n.language === "rn") {
        return item.ruName;
      } else {
        return item.enName;
      }
    }),
    datasets: [
      {
        label: t("total.report.tableHover"),
        backgroundColor: "#F18B8B",
        data: report.bottom3Skills.map((item) => item.level),
      },
    ],
  };

  const mpiremain = 100 - report.iqPercentage;
  const ciremain = 100 - report.personalityPercentage;

  const pieData = {
    labels: ["нийцэл", ""],
    datasets: [
      {
        data: [report.iqPercentage.toFixed(0), mpiremain],
        backgroundColor: ["transparent", "transparent"],
        borderColor: ["#06B496", "transparent"], // Clear inside border
        borderWidth: 2, // Add outer border
        borderAlign: "center", // Align outer border to center
        cutout: "99%", // Add a cutout to make space for the outer border
      },
    ],
  };

  const pieData2 = {
    labels: ["нийцэл", ""],
    datasets: [
      {
        data: [report.personalityPercentage.toFixed(0), ciremain],
        backgroundColor: ["transparent", "transparent"],
        borderColor: ["#06B496", "transparent"], // Clear inside border
        borderWidth: 2, // Add outer border
        borderAlign: "center", // Align outer border to center
        cutout: "99%", // Add a cutout to make space for the outer border
      },
    ],
  };

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const option = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        reverse: true,
      },
      y: {
        position: "right",
      },
    },
  };

  const coptions2 = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        max: 5,
      },
    },
  };

  const formattedCi = parseFloat(data.ci).toFixed(2);
  const formattedmpi = parseFloat(data.mpi).toFixed(2);

  return (
    <div
      className="p-9/12 m-auto pt-11 reportDownloa relative"
      ref={longImageRef}
    >
      {console.log(report)}
      <div className="section">
        <div className="flex justify-between">
          <div className="flex items-center gap-5">
            <img
              className="w-[100px] h-[100px] border-2 rounded-full border-black "
              src="/user.svg"
              alt="user"
            />
            <div>
              <p className="text-2xl font-bold">
                {data.lastName} {data.name}
              </p>
              <p className="text-xl my-4">{data.assessmentName}</p>
              <div>
                <div className="flex items-center gap-5">
                  <p> {t("assessmentProfile.phone")}:</p>
                  <p>{data.phoneNumber}</p>
                </div>
                <div className="flex items-center gap-5">
                  <p> {t("assessmentProfile.email")}:</p> <p>{data.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <p className="text-lg font-bold mx-20">
            {t("assessmentProfile.title")}
          </p>
        </div>
        <div className="flex justify-center mt-8 px-10">
          <div style={{ width: "1000px " }}>
            <Bar data={Cdata} options={option} />
          </div>
        </div>
        <div
          id="downloadButton"
          className="flex justify-end items-center gap-5 mt-10 downloadBtn"
        >
          <button
            onClick={() => {
              downloadLongImage();
            }}
            className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#324D72] hover:bg-black hover:text-white"
          >
            <TfiSave className="h-5 w-5" />
            {t("assessmentProfile.downloadReport")}
          </button>
          {/* <button className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#6CB7A1] hover:bg-black hover:text-white">
          <AiOutlinePrinter className="h-5 w-5" />
          Тайлан хэвлэх
        </button> */}
        </div>
      </div>
      <div className="section">
        <div className="text-center text-2xl mb-10 font-bold mt-20">
          <p>{t("assessmentProfile.title2")}</p>
        </div>
        <div>
          <div className="w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-3 text-base top-10"}
              top={40}
              left={40}
              hoverText={t("total.report.lilTitleDesc1")}
            />
            <p className="text-xl font-bold bottom-Space">
              {t("assessmentProfile.lvl1Intelligence")}
            </p>
          </div>
          <div>
            <div>
              <div className="flex justify-center">
                <table className="ms-10 mt-5">
                  <tbody>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/inductive.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("inductive"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <div
                                className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                                style={{
                                  left: `${
                                    findSkill("inductive").expectedLevel * 24 -
                                    24
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("inductive").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("inductive").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("inductive").level - 1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-6"}
                            hoverText={t("total.report.inductiveDesc")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/processingSpeed.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("processingSpeed"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <div
                                className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                                style={{
                                  left: `${
                                    findSkill("processingSpeed").expectedLevel *
                                      24 -
                                    24
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("processingSpeed").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("processingSpeed").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("processingSpeed").level - 1 >=
                                      value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-10"}
                            hoverText={t("total.report.processingSpeedDesc")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/attention.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("attention"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <div
                                className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                                style={{
                                  left: `${
                                    findSkill("attention").expectedLevel * 24 -
                                    24
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("attention").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("attention").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("attention").level - 1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-6"}
                            hoverText={t("total.report.attentionDesc")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/quantitative.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("quantitativeReasoing"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <div
                                className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                                style={{
                                  left: `${
                                    findSkill("quantitativeReasoing")
                                      .expectedLevel *
                                      24 -
                                    24
                                  }%`,
                                }}
                              />
                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={
                                  findSkill("quantitativeReasoing").level - 1
                                }
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("quantitativeReasoing").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("quantitativeReasoing").level -
                                        1 >=
                                      value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-14"}
                            hoverText={t("total.report.quantitativeDesc")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/deductive.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("deductive"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <div
                                className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                                style={{
                                  left: `${
                                    findSkill("deductive").expectedLevel * 24 -
                                    24
                                  }%`,
                                }}
                              />

                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("deductive").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("deductive").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("deductive").level - 1 >= value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-8"}
                            hoverText={t("total.report.deductiveDesc")}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 flex items-center pe-20">
                        <div className="w-10">
                          <img
                            src="/assets/report/memory.png"
                            className="h-[30]px w-[30px]"
                            alt="logo"
                          />
                        </div>
                        <p className="text-lg font-bold">
                          {getSkillName(findSkill("workingMemory"))}
                        </p>
                      </td>
                      <td>
                        <div className="flex items-center gap-5">
                          <div className="flex items-center">
                            <div className="relative w-[400px]">
                              <div
                                className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                                style={{
                                  left: `${
                                    findSkill("workingMemory").expectedLevel *
                                      24 -
                                    24
                                  }%`,
                                }}
                              />
                              <p className="absolute text-base -bottom-5 -left-5">
                                {t("assessmentProfile.low")}
                              </p>
                              <p className="absolute text-base -bottom-5 -right-5">
                                {t("assessmentProfile.high")}
                              </p>
                              <input
                                type="range"
                                max="5"
                                min="1"
                                value={findSkill("workingMemory").level - 1}
                                disabled
                                className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                                style={{
                                  backgroundImage: getGradientColor(
                                    findSkill("workingMemory").level - 1
                                  ),
                                  cursor: "pointer",
                                }}
                              />
                              <div className="absolute top-[22px] -mt-1 h-1 w-full">
                                <span
                                  className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                                  style={{ left: "2%" }}
                                ></span>
                                {[1, 2, 3, 4].map((value) => (
                                  <span
                                    key={value}
                                    className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                      findSkill("workingMemory").level - 1 >=
                                      value
                                        ? "bg-[#66BCCE]"
                                        : "bg-[#D9D9D9]"
                                    }`}
                                    style={{ left: `${value * 24 + 2}%` }}
                                  ></span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <HoverComponent
                            svg={
                              <svg
                                width="210"
                                height="193"
                                viewBox="0 0 258 193"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                                  fill="white"
                                  stroke="#464A54"
                                  stroke-width="0.5"
                                />
                              </svg>
                            }
                            top={10}
                            left={20}
                            textStyle={"text-sm mx-3 top-14"}
                            hoverText={t("total.report.workingMemoryDesc")}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-start mx-10 mt-20">
                <div>
                  <div className="flex justify-center">
                    <p className="text-lg text-[#324D72] font-bold text-center w-[300px]">
                      {t("total.report.NormalDistGraphTitle1")}
                    </p>
                  </div>
                  <div className="flex items-center gap-10 my-3">
                    <p>
                      {t("Report.mpiScore")} - {formattedmpi}
                    </p>
                    <p>
                      {t("assessmentProfile.Summary")}:{" "}
                      {(() => {
                        if (i18n.language === "mn") {
                          return report?.ilevel.giiName;
                        } else if (i18n.language === "rn") {
                          return <p>{report?.ilevel.ruGiName}</p>;
                        } else {
                          switch (report?.ilevel?.giiLvl) {
                            case 1:
                              return "Unsatisfactory";
                            case 2:
                              return "Needs Improvement";
                            case 3:
                              return "Satisfactory";
                            case 4:
                              return "Good";
                            case 5:
                              return "Excellent";
                            default:
                              return "Default Name";
                          }
                        }
                      })()}{" "}
                      ({report?.ilevel?.giiLvl})
                    </p>
                  </div>
                  <NormalDistributionChart targetValue={formattedmpi} />
                  <p className="mt-1 text-xs">
                    *{report.assPlayer?.player?.name}{" "}
                    {t("assessmentProfile.intel1Desc1")}{" "}
                    {report.mpiPercent.toFixed(2)}%{" "}
                    {t("assessmentProfile.intel1Desc2")}
                  </p>
                </div>
                <div className="me-[100px]">
                  <p className="text-lg w-[202px] text-[#324D72] font-bold text-center mb-10">
                    {t("assessmentProfile.circleGraphTitle1")}
                  </p>
                  <div>
                    <div className="relative ms-14">
                      <div
                        className={`absolute top-[30%] ${
                          Number(report.iqPercentage) === 100
                            ? "left-[10%]"
                            : "left-[15%]"
                        }`}
                      >
                        <p className="text-4xl font-bold bottom-percent">
                          {report.iqPercentage.toFixed(0)}%
                        </p>
                      </div>
                      <div style={{ height: "100px", width: "100px" }}>
                        <Pie data={pieData} options={pieOptions} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-20 pb-10">
          <div className="relative w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5 mb-8">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-4 text-base top-10"}
              top={40}
              left={40}
              hoverText={t("total.report.lilTitleDesc2")}
            />
            <p className="text-xl font-bold bottom-Space">
              {t("assessmentProfile.lvl2Intelligence")}
            </p>
          </div>
          <div className="flex justify-center">
            <table className="ms-10">
              <tbody>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/critical-thinking.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("criticalThinking"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <div
                            className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                            style={{
                              left: `${
                                findSkill("criticalThinking").expectedLevel *
                                  24 -
                                24
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("criticalThinking").level}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("criticalThinking").level
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("criticalThinking").level >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="258"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={20}
                        left={12}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText={t("total.report.criticalThinkingDesc")}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/pattern.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("patternRecognition"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <div
                            className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                            style={{
                              left: `${
                                findSkill("patternRecognition").expectedLevel *
                                  24 -
                                24
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("patternRecognition").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("patternRecognition").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("patternRecognition").level - 1 >=
                                  value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText={t("total.report.patternRecognitionDesc")}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/decision-making.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("decisionMaking"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <div
                            className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                            style={{
                              left: `${
                                findSkill("decisionMaking").expectedLevel * 24 -
                                24
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("decisionMaking").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("decisionMaking").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("decisionMaking").level - 1 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText={t("total.report.decisionMakingDesc")}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/learning.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("learningRate"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <div
                            className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                            style={{
                              left: `${
                                findSkill("learningRate").expectedLevel * 24 -
                                24
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("learningRate").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("learningRate").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("learningRate").level - 1 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-10"}
                        hoverText={t("total.report.learningRateDesc")}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="py-2 flex items-center pe-20">
                    <div className="w-10">
                      <img
                        src="/assets/report/planning.png"
                        className="h-[30]px w-[30px]"
                        alt="logo"
                      />
                    </div>
                    <p className="text-lg font-bold">
                      {getSkillName(findSkill("planning"))}
                    </p>
                  </td>
                  <td>
                    <div className="flex items-center gap-5">
                      <div className="flex items-center">
                        <div className="relative w-[400px]">
                          <div
                            className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                            style={{
                              left: `${
                                findSkill("planning").expectedLevel * 24 - 24
                              }%`,
                            }}
                          />

                          <input
                            type="range"
                            max="5"
                            min="1"
                            value={findSkill("planning").level - 1}
                            disabled
                            className="appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                            style={{
                              backgroundImage: getGradientColor(
                                findSkill("planning").level - 1
                              ),
                              cursor: "pointer",
                            }}
                          />
                          <p className="absolute text-base -bottom-5 -left-5">
                            {t("assessmentProfile.low")}
                          </p>
                          <p className="absolute text-base -bottom-5 -right-5">
                            {t("assessmentProfile.high")}
                          </p>
                          <div className="absolute top-[22px] -mt-1 h-1 w-full">
                            <span
                              className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                              style={{ left: "2%" }}
                            ></span>
                            {[1, 2, 3, 4].map((value) => (
                              <span
                                key={value}
                                className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${
                                  findSkill("planning").level - 1 >= value
                                    ? "bg-[#66BCCE]"
                                    : "bg-[#D9D9D9]"
                                }`}
                                style={{ left: `${value * 24 + 2}%` }}
                              ></span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <HoverComponent
                        svg={
                          <svg
                            width="210"
                            height="193"
                            viewBox="0 0 258 193"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                              fill="white"
                              stroke="#464A54"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                        top={10}
                        left={20}
                        textStyle={"text-sm mx-3 top-6"}
                        hoverText={t("total.report.planningDesc")}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-start mx-10 mt-20">
            <div>
              <div className="flex justify-center">
                <p className="text-lg text-[#324D72] text-center font-bold w-[300px]">
                  {t("total.report.NormalDistGraphTitle2")}
                </p>
              </div>

              <div className="flex items-center gap-10 my-3">
                <p>
                  СI {t("total.report.score")} - {formattedCi}
                </p>
                <p>
                  {t("assessmentProfile.Summary")}:{" "}
                  {(() => {
                    if (i18n.language === "mn") {
                      return report?.ilevel.ciName;
                    } else if (i18n.language === "rn") {
                      switch (report?.ilevel?.ciLvl) {
                        case 1:
                          return "Недостаточный";
                        case 2:
                          return "Удовлетворительный";
                        case 3:
                          return "Средний";
                        case 4:
                          return "Good";
                        case 5:
                          return "Высокий";
                        default:
                          return "Очень высокий";
                      }
                    } else {
                      switch (report?.ilevel?.ciLvl) {
                        case 1:
                          return "Unsatisfactory";
                        case 2:
                          return "Needs Improvement";
                        case 3:
                          return "Satisfactory";
                        case 4:
                          return "Good";
                        case 5:
                          return "Excellent";
                        default:
                          return "Default Name";
                      }
                    }
                  })()}
                </p>
              </div>
              <NormalDistributionChart targetValue={formattedCi} />
              <p className="mt-1 text-xs">
                *{report.assPlayer?.player?.name}{" "}
                {t("assessmentProfile.intel1Desc1")}{" "}
                {report.ciPercent.toFixed(2)}%{" "}
                {t("assessmentProfile.intel1Desc2")}
              </p>
            </div>
            <div className="me-[100px] ">
              <p className="text-lg w-[200px] text-[#324D72] font-bold text-center mb-5">
                {t("total.report.circleGraphTitle2")}
              </p>
              <div className="ms-10">
                <div className="relative ">
                  <div
                    className={`absolute top-[30%] ${
                      Number(report.iqPercentage) === 100
                        ? "left-[10%]"
                        : "left-[14%]"
                    }`}
                  >
                    <p className="text-4xl font-bold bottom-percent">
                      {report.personalityPercentage.toFixed(0)}%
                    </p>
                  </div>
                  <div style={{ height: "100px", width: "100px" }}>
                    <Pie data={pieData2} options={pieOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="pb-20 pe-10">
          <div className="w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-2 text-base top-2"}
              top={40}
              left={40}
              hoverText={t("total.report.lilTitleDesc3")}
            />
            <p className="text-xl font-bold bottom-Space">
              {t("total.report.lilTitle3")}
            </p>
          </div>
          <div className="flex justify-center">
            <div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex items-center w-[300px] justify-center gap-2">
                    <img src="/assets/report/curiosity.png" alt="Logo" />
                    <p className="text-center text-xl font-bold bottom-spacing">
                      {getSkillName(findSkill("curiosity"))}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.curiosity1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.curiosity3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("curiosity").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("curiosity").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />

                    <style jsx>{`
                      input[type="range"]::-webkit-slider-thumb {
                        appearance: none;
                        width: 0; /* Hide the thumb */
                        height: 0; /* Hide the thumb */
                      }

                      input[type="range"]::-moz-range-thumb {
                        appearance: none;
                        width: 0;
                        height: 0;
                      }

                      input[type="range"]::-ms-thumb {
                        appearance: none;
                        width: 0;
                        height: 0;
                      }
                    `}</style>

                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("curiosity").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2 ">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.curiosity4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.curiosity2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center ">
                  <div className="flex items-center w-[300px] gap-2 justify-center">
                    <img
                      src="/assets/report/risk-taking.png"
                      className="w-10 h-10"
                      alt="Logo"
                    />
                    <p className="text-center text-xl font-bold bottom-spacing">
                      {getSkillName(findSkill("riskTaking"))}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.riskTaking1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.riskTaking3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("riskTaking").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("riskTaking").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("riskTaking").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-4"}
                      hoverText={t("Report.riskTaking4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.riskTaking2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex items-center w-full gap-2 justify-center">
                    <img
                      src="/assets/report/adaptability.png"
                      className="w-10 h-10"
                      alt="Logo"
                    />
                    <p className="text-center text-xl font-bold bottom-spacing">
                      {getSkillName(findSkill("adaptability"))}
                    </p>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.adaptability1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.adaptability3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("adaptability").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("adaptability").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("adaptability").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="240"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.adaptability4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.adaptability2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex items-center w-full gap-2 justify-center">
                      <img
                        src="/assets/report/cooperative.png"
                        className="w-10 h-10"
                        alt="Logo"
                      />
                      <p className="text-center text-xl font-bold bottom-spacing">
                        {getSkillName(findSkill("cooperative"))}
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.cooperative1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.cooperative3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("cooperative").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("cooperative").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("cooperative").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-4"}
                      hoverText={t("Report.cooperative4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.cooperative2")}
                    </p>
                  </div>
                </div>
              </div>{" "}
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/trust.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl font-bold bottom-spacing">
                          {getSkillName(findSkill("trusting"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.trusting1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="240"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-3"}
                      hoverText={t("Report.trusting3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("trusting").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("trusting").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("trusting").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-3"}
                      hoverText={t("Report.trusting4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.trusting2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/assertive.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl w-[250px] font-bold bottom-spacing">
                          {getSkillName(findSkill("assertiveness"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.assertiveness1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText={t("Report.assertiveness3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("assertiveness").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("assertiveness").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${
                          findSkill("assertiveness").level * 24 - 24.5
                        }%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="280"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText={t("Report.assertiveness4")}
                    />
                    <p className="text-sm text-center w-[230px]">
                      {t("Report.assertiveness2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/goal-setting.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl font-bold bottom-spacing">
                          {getSkillName(findSkill("consistence"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.consistence1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-4"}
                      hoverText={t("Report.consistence3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("consistence").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("consistence").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("consistence").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-xs mx-2 top-4"}
                      hoverText={t("Report.consistence4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.consistence2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <div className="flex justify-center">
                  <div className="flex justify-center">
                    <div className="flex justify-center">
                      <div className="flex items-center w-full gap-2 justify-center">
                        <img
                          src="/assets/report/consistency.png"
                          className="w-10 h-10"
                          alt="Logo"
                        />
                        <p className="text-center text-xl font-bold bottom-spacing">
                          {getSkillName(findSkill("goalSetting"))}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  <div className="flex items-center gap-2 px-2">
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.goalSetting1")}
                    </p>
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText={t("Report.goalSetting3")}
                    />
                  </div>
                  <div className="relative">
                    <div
                      className={`absolute -top-2 w-0 h-0 border-l-[8px] border-r-[8px] border-b-[8px] rotate-180 border-transparent border-b-[#F18B8B]`}
                      style={{
                        left: `${
                          findSkill("goalSetting").expectedLevel * 24 - 24
                        }%`,
                      }}
                    />

                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={findSkill("goalSetting").level}
                      disabled
                      className="w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                      }}
                    />
                    <div
                      className="absolute top-2 w-5 h-5 rounded-full border border-[#666874] p-0.5 bg-white"
                      style={{
                        left: `${findSkill("goalSetting").level * 24 - 24.5}%`,
                      }}
                    >
                      <div className="bg-[#55D6A0] w-full h-full rounded-full "></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 px-2">
                    <HoverComponent
                      svg={
                        <svg
                          width="300"
                          height="220"
                          viewBox="0 0 258 193"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.75 14.5V14.4786L2.74637 14.4575L0.323843 0.406898L15.4033 6.73055L15.4497 6.75H15.5H257.25V192.25H2.75V14.5Z"
                            fill="white"
                            stroke="#464A54"
                            strokeWidth="0.5"
                          />
                        </svg>
                      }
                      top={20}
                      left={20}
                      textStyle={"text-sm mx-2 top-6"}
                      hoverText={t("Report.goalSetting4")}
                    />
                    <p className="text-sm text-center w-[220px]">
                      {t("Report.goalSetting2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div>
          <p className="text-center text-2xl font-bold pt-20">
            {t("assessmentProfile.Summary")}
          </p>
          <div className="flex items-start justify-between mt-20 px-20">
            <div>
              <p className="text-xl text-center font-bold mb-5">
                {t("total.report.top3Skill")}
              </p>
              <div className="w-[300px] h-auto">
                <Bar data={Cdata2} options={coptions2} />
              </div>
            </div>
            <div>
              <p className="text-xl text-center font-bold mb-5">
                {t("total.report.bot3Skill")}
              </p>
              <div className="w-[300px] h-auto">
                <Bar data={Cdata3} options={coptions2} />
              </div>
            </div>
          </div>
        </div>
        <div className="mx-[100px] mt-20">
          <p className="text-md pb-20">
            <span className="font-black">
              {" "}
              {t("assessmentProfile.explanationT")}:
            </span>{" "}
            {t("assessmentProfile.explanation")}:
          </p>
          <div
            id="downloadButton"
            className="flex justify-end items-center gap-5 my-10 downloadBtn"
          >
            <button
              onClick={() => {
                downloadLongImage();
              }}
              className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#324D72] hover:bg-black hover:text-white"
            >
              <TfiSave className="h-5 w-5" />
              {t("assessmentProfile.downloadReport")}
            </button>
            {/* <button onClick={scrollToTop}>Scroll To Top</button> */}
            {/* <button className="px-6 py-2 border rounded-lg text-lg font-bold flex items-center gap-2 border-[#6CB7A1] hover:bg-black hover:text-white">
            <AiOutlinePrinter className="h-5 w-5" />
            Тайлан хэвлэх
          </button> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default NewGameData;
