import { useEffect, useState } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import "./../../App.css";

const unityContext = new UnityContext({
    productName: "Test",
    companyName: "Metacog",

    loaderUrl: "../../../../unity/burnout/BurnoutBuild.loader.js",
    dataUrl: "../../../../unity/burnout/BurnoutBuild.data",
    frameworkUrl: "../../../../unity/burnout/BurnoutBuild.framework.js",
    codeUrl: "../../../../unity/burnout/BurnoutBuild.wasm",
});


function Burnout() {
    const [orientation, setOrientation] = useState('portrait');
    const [progress, setProgress] = useState(0);
    const [isSurvey, setIsSurvey] = useState(false);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const updateOrientation = () => {
        setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
        setViewportHeight(window.innerHeight);
    };
    
    useEffect(function () {
        window.alert = function () { };
        window.addEventListener('resize', updateOrientation);
        updateOrientation();

        unityContext.on("progress", (progression) => {
            setProgress(progression * 100);
            unityContext.send("MetaCog", "ChangeLang", '0');
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        });

        let st = 0;
        unityContext.on("GameOver1", function (result) {
            console.log("GameOver1 Results = ", result);
            st++;
            unityContext.send("MetaCog", "GameLang", `${st},0`);
        });

        return () => window.removeEventListener('resize', updateOrientation);

    }, []);

    return (
        <div style={{ width: '100vw', height: `${viewportHeight}px` }}>
            {
                orientation === 'portrait'
                    ? <div className="flex flex-col items-center justify-center h-full w-full">
                        <img src="/assets/rotate.webp" alt="" />
                        <p>Сайн байна уу</p>
                        <p>Та утсаа эргүүлнэ үү!</p>
                        <p>Баярлалаа</p>
                    </div>
                    : <Unity style={{ width: '100%', height: '100%' }} unityContext={unityContext} />
            }
        </div>
    )
}
export default Burnout;
