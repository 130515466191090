import { useLocation } from "react-router-dom";

export default function CongratzCustom() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const message = params.get("message") || "Congratulations! 🎉";

  return (
    <div className="flex items-center justify-center h-screen text-3xl font-bold text-green-600">
      {message}
    </div>
  );
}
